<script>
import { clickOutside } from 'src/directives/click-outside';
import arrowImage from './arrow.png';

export default {
  directives: { clickOutside },

  props: {
    accessKeys: {
      type: Array,
      default: () => ['id', 'value'],
    },

    activeItem: {
      type: [Object, null],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      arrowImage,
      show: false,
    };
  },

  computed: {
    accessKey() {
      return this.accessKeys[0];
    },

    accessValue() {
      return this.accessKeys[1];
    },
  },

  methods: {
    onClickItem(item) {
      this.$emit('select', item);
      this.closeMenu();
    },

    openMenu() {
      this.show = true;
    },

    onClickSelected() {
      if (this.show) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },

    closeMenu() {
      this.show = false;
    },
  },
};
</script>

<template>
  <div v-clickOutside="closeMenu" class="c-select-menu">
    <div @click="onClickSelected" class="c-select-menu__selected">
      <template v-if="activeItem">
        {{ activeItem[accessValue] }}
      </template>
      <template v-else>&nbsp;</template>
      <img class="c-select-menu__arrow" :src="arrowImage" />
    </div>

    <ul v-show="show" class="c-select-menu__list">
      <li v-for="item in items" :key="item[accessKey]" @click="onClickItem(item)" class="c-select-menu__item">
        {{ item[accessValue] }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
