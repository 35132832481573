<script>
import markImage from './mark.png';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      markImage,
    };
  },

  methods: {
    onClick() {
      this.$emit('check');
    },
  },
};
</script>

<template>
  <div :class="['c-checkbox', { error }]">
    <div @click="onClick" class="c-checkbox__body">
      <img v-show="value" :src="markImage" class="c-checkbox__mark" />
    </div>

    <div class="c-checkbox__content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
