<script>
import { ANALYTICS } from 'src/constants/analytics';
import { clearPhoneNumber } from 'src/utils/clearPhoneNumber';
import { geoAPI, formsAPI } from 'src/api';
import { mapGetters, mapActions } from 'vuex';
import Popup from 'src/components/Popup';
import AgreementText from './AgreementText';
import Button from './Button';
import Checkbox from './Checkbox';
import ErrorBlock from './ErrorBlock';
import Input from './Input';
import Label from './Label';
import SearchInput from './SearchInput';
import SelectMenu from './SelectMenu';

const generateTimeItems = () => {
  return Array.from(new Array(24)).map((_, i) => ({
    id: i,
    name: String(i).length < 2 ? `0${i}:00` : `${i}:00`,
  }));
};

const generateState = () => ({
  show: false,
  loading: false,

  name: '',
  phone: '',
  agreement: false,

  addressItem: null,
  addressItems: [],

  timeItems: generateTimeItems(),
  timeFromItem: generateTimeItems().find(i => i.id === 9),
  timeToItem: generateTimeItems().find(i => i.id === 21),

  errors: {
    name: '',
    phone: '',
    address: '',
    agreement: false,
  },
});

export default {
  props: {
    label: {
      type: String,
      default: 'Заказать звонок',
    },
  },

  components: {
    Popup,
    Input,
    Label,
    ErrorBlock,
    SearchInput,
    SelectMenu,
    Checkbox,
    AgreementText,
    Button,
  },

  data() {
    return generateState();
  },

  computed: {
    ...mapGetters('profile', { profile: 'fields' }),
    ...mapGetters('auth', ['recaptchaToken']),
  },

  methods: {
    ...mapActions('auth', ['getRecaptchaToken']),
    showPopup(ev) {
      ev?.preventDefault();

      if (!this.addressItem && this.profile.currentCityName) {
        this.addressItem = { id: -1, name: this.profile.currentCityName };
      }

      this.show = true;
    },

    closePopup() {
      this.show = false;
    },

    onInputName({ currentTarget }) {
      const { value } = currentTarget;

      this.errors.name = '';
      this.name = value;
    },

    onInputPhone(value) {
      this.errors.phone = '';
      this.phone = value;
    },

    addPrefixTo(name) {
      return `request-callback-popup-${name}`;
    },

    onSearchAddress(values) {
      this.addressItems = values;
    },

    onChooseAddress(value) {
      this.errors.address = '';
      this.addressItem = value;
    },

    onChooseTimeFromItem(value) {
      this.timeFromItem = value;
    },

    onChooseTimeToItem(value) {
      this.timeToItem = value;
    },

    onCheckAgreement() {
      this.agreement = !this.agreement;
      this.errors.agreement = false;
    },

    searchAddress(text) {
      return geoAPI.cities({ q: text });
    },

    async onSubmit() {
      const { agreement, name, phone, addressItem, timeFromItem, timeToItem } = this;

      let hasError = false;

      if (!agreement) {
        this.errors.agreement = true;
        hasError = true;
      }

      if (!name) {
        this.errors.name = 'Поле не заполнено';
        hasError = true;
      }

      if (!phone) {
        this.errors.phone = 'Поле не заполнено';
        hasError = true;
      }

      if (!addressItem) {
        this.errors.address = 'Поле не заполнено';
        hasError = true;
      }

      if (hasError) {
        return;
      }

      try {
        await this.getRecaptchaToken().finally(async () => {
          const payload = {
            name,
            phone: clearPhoneNumber(phone),
            city: addressItem.name,
            firstTime: timeFromItem.name,
            lastTime: timeToItem.name,
            captcha: this.recaptchaToken,
          };
          await formsAPI.callUs(payload);
          Object.assign(this, generateState());
          globalThis.emitter.emit('addNotification', 'Мы вам позвоним!');
          window.xcore.trigger(ANALYTICS.CALLBACK_FORM);
        });
      } catch (e) {
        console.error(e);

        const message =
          // eslint-disable-next-line camelcase
          e?.response?.data?.reason_phrase ||
          'Возможность заказать звонок временно недоступна. Пожалуйста, повторите попытку позже';

        globalThis.emitter.emit('addNotification', message);
      }
    },

    showRequestCallback(value) {
      if (value) {
        this.showPopup();
      } else {
        this.closePopup();
      }
    },
  },

  mounted() {
    globalThis.emitter.on('showRequestCallback', this.showRequestCallback);
  },

  beforeDestroy() {
    globalThis.emitter.off('showRequestCallback', this.showRequestCallback);
  },
};
</script>

<template>
  <Popup class="c-request-callback" v-if="show" kind="custom" @close="closePopup">
    <div class="request-callback-popup">
      <div class="request-callback-popup__header">
        <div class="request-callback-popup__title">Обратный звонок</div>
        <div class="request-callback-popup__subtitle">Заказать звонок</div>
      </div>

      <div class="request-callback-popup__body">
        <div class="request-callback-popup__block">
          <Label :for="addPrefixTo('name')">Ваше Имя *</Label>

          <Input
            :id="addPrefixTo('name')"
            type="text"
            autocomplete="off"
            @input="onInputName"
            :value="name"
            maxlength="255"
            placeholder="Ваше имя"
          />

          <ErrorBlock :value="errors.name" />
        </div>

        <div class="request-callback-popup__block">
          <Label :for="addPrefixTo('phone')">Ваш телефон *</Label>

          <Input
            :id="addPrefixTo('phone')"
            kind="with-mask"
            autocomplete="off"
            @input="onInputPhone"
            :value="phone"
            maxlength="255"
            placeholder="+7 (999) 999-99-99"
            :mask="'+{7} (000) 000-00-00'"
            type="tel"
            radix="."
            :unmask="false"
          />

          <ErrorBlock :value="errors.phone" />
        </div>

        <div class="request-callback-popup__block">
          <Label>Город</Label>

          <SearchInput
            :accessKeys="['id', 'name']"
            :handler="searchAddress"
            :items="addressItems"
            :activeItem="addressItem"
            @search="onSearchAddress"
            @select="onChooseAddress"
            placeholder="Поиск по городу или населенному пункту"
          />

          <ErrorBlock :value="errors.address" />
        </div>

        <div class="request-callback-popup__block">
          <Label>Время звонка с</Label>

          <SelectMenu
            :accessKeys="['id', 'name']"
            :items="timeItems"
            :activeItem="timeFromItem"
            @select="onChooseTimeFromItem"
          />
        </div>

        <div class="request-callback-popup__block request-callback-popup__block_time">
          <Label>Время звонка до</Label>

          <SelectMenu
            :accessKeys="['id', 'name']"
            :items="timeItems"
            :activeItem="timeToItem"
            @select="onChooseTimeToItem"
          />
        </div>

        <div class="request-callback-popup__block request-callback-popup__block_agreement">
          <Checkbox :error="errors.agreement" @check="onCheckAgreement" :value="agreement">
            <AgreementText>Предоставляя свои персональные данные,</AgreementText>
          </Checkbox>
        </div>

        <div class="request-callback-popup__block">
          <AgreementText>
            вы подтверждаете, что вам больше 18 лет, и соглашаетесь с
            <a href="/public-oferta/" target="_blank">офертой</a>
            и <a href="/personalnye-dannye/" target="_blank">политикой конфиденциальности.</a>
          </AgreementText>
        </div>

        <div class="request-callback-popup__block">
          <Button :disabled="loading" @click="onSubmit">жду звонка</Button>
        </div>
      </div>
    </div>
  </Popup>
</template>

<style lang="scss" scoped src="./style.scss"></style>
