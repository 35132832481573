var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "Popup",
        {
          staticClass: "c-request-callback",
          attrs: { kind: "custom" },
          on: { close: _vm.closePopup }
        },
        [
          _c("div", { staticClass: "request-callback-popup" }, [
            _c("div", { staticClass: "request-callback-popup__header" }, [
              _c("div", { staticClass: "request-callback-popup__title" }, [
                _vm._v("Обратный звонок")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "request-callback-popup__subtitle" }, [
                _vm._v("Заказать звонок")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "request-callback-popup__body" }, [
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c("Label", { attrs: { for: _vm.addPrefixTo("name") } }, [
                    _vm._v("Ваше Имя *")
                  ]),
                  _vm._v(" "),
                  _c("Input", {
                    attrs: {
                      id: _vm.addPrefixTo("name"),
                      type: "text",
                      autocomplete: "off",
                      value: _vm.name,
                      maxlength: "255",
                      placeholder: "Ваше имя"
                    },
                    on: { input: _vm.onInputName }
                  }),
                  _vm._v(" "),
                  _c("ErrorBlock", { attrs: { value: _vm.errors.name } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c("Label", { attrs: { for: _vm.addPrefixTo("phone") } }, [
                    _vm._v("Ваш телефон *")
                  ]),
                  _vm._v(" "),
                  _c("Input", {
                    attrs: {
                      id: _vm.addPrefixTo("phone"),
                      kind: "with-mask",
                      autocomplete: "off",
                      value: _vm.phone,
                      maxlength: "255",
                      placeholder: "+7 (999) 999-99-99",
                      mask: "+{7} (000) 000-00-00",
                      type: "tel",
                      radix: ".",
                      unmask: false
                    },
                    on: { input: _vm.onInputPhone }
                  }),
                  _vm._v(" "),
                  _c("ErrorBlock", { attrs: { value: _vm.errors.phone } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c("Label", [_vm._v("Город")]),
                  _vm._v(" "),
                  _c("SearchInput", {
                    attrs: {
                      accessKeys: ["id", "name"],
                      handler: _vm.searchAddress,
                      items: _vm.addressItems,
                      activeItem: _vm.addressItem,
                      placeholder: "Поиск по городу или населенному пункту"
                    },
                    on: {
                      search: _vm.onSearchAddress,
                      select: _vm.onChooseAddress
                    }
                  }),
                  _vm._v(" "),
                  _c("ErrorBlock", { attrs: { value: _vm.errors.address } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c("Label", [_vm._v("Время звонка с")]),
                  _vm._v(" "),
                  _c("SelectMenu", {
                    attrs: {
                      accessKeys: ["id", "name"],
                      items: _vm.timeItems,
                      activeItem: _vm.timeFromItem
                    },
                    on: { select: _vm.onChooseTimeFromItem }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "request-callback-popup__block request-callback-popup__block_time"
                },
                [
                  _c("Label", [_vm._v("Время звонка до")]),
                  _vm._v(" "),
                  _c("SelectMenu", {
                    attrs: {
                      accessKeys: ["id", "name"],
                      items: _vm.timeItems,
                      activeItem: _vm.timeToItem
                    },
                    on: { select: _vm.onChooseTimeToItem }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "request-callback-popup__block request-callback-popup__block_agreement"
                },
                [
                  _c(
                    "Checkbox",
                    {
                      attrs: {
                        error: _vm.errors.agreement,
                        value: _vm.agreement
                      },
                      on: { check: _vm.onCheckAgreement }
                    },
                    [
                      _c("AgreementText", [
                        _vm._v("Предоставляя свои персональные данные,")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c("AgreementText", [
                    _vm._v(
                      "\n          вы подтверждаете, что вам больше 18 лет, и соглашаетесь с\n          "
                    ),
                    _c(
                      "a",
                      { attrs: { href: "/public-oferta/", target: "_blank" } },
                      [_vm._v("офертой")]
                    ),
                    _vm._v("\n          и "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/personalnye-dannye/",
                          target: "_blank"
                        }
                      },
                      [_vm._v("политикой конфиденциальности.")]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request-callback-popup__block" },
                [
                  _c(
                    "Button",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("жду звонка")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }