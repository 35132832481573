var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "header__line header__line--nav-links header-nav" },
    [
      _vm._l(_vm.catalogMenu, function(category, i) {
        return [
          _c(
            "div",
            {
              key: "adaptive-" + i,
              staticClass: "mobile-menu__item mobile-menu-open-category"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "mobile-menu__item-link mobile-menu__item-link--uppercase",
                  class: {
                    "mobile-menu__item-link--no-img": !category.image_header
                  },
                  attrs: { href: category.link }
                },
                [
                  category.image_header
                    ? _c("img", {
                        staticClass: "mobile-menu__item-link-img",
                        attrs: {
                          src: category.image_header,
                          alt: category.name
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "mobile-menu__item-link-text",
                      class: {
                        "mobile-menu__item-link-text--no-category": !category
                          .children.length
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(category.name) + "\n        "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              category.children.length ? _c("MenuArrowRightIcon") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          category.children.length
            ? _c(
                "div",
                {
                  key: i,
                  class: [
                    "mobile-menu__submenu",
                    "js-accorgion-body",
                    "mobile-menu__submenu--" + category.class
                  ]
                },
                [
                  _vm._l(
                    _vm.getLocalAvailableGroups(category.children),
                    function(ref, ii) {
                      var name = ref.name
                      var children = ref.children
                      return [
                        _c(
                          "span",
                          {
                            key: "adaptive-" + i + "-" + ii,
                            staticClass: "mobile-menu__submenu-title"
                          },
                          [_vm._v(_vm._s(name))]
                        ),
                        _vm._v(" "),
                        _vm._l(children, function(child, index) {
                          return _c(
                            "div",
                            { key: "children-" + index },
                            [
                              _vm._l(child, function(subcategory) {
                                return _c(
                                  "a",
                                  {
                                    key: subcategory.name,
                                    staticClass: "mobile-menu__submenu-link",
                                    attrs: { href: subcategory.link }
                                  },
                                  [_vm._v(_vm._s(subcategory.name))]
                                )
                              }),
                              _vm._v(" "),
                              _vm.showSeeAll(name)
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mobile-menu__submenu-see-all",
                                      attrs: { href: category.link }
                                    },
                                    [_vm._v("Смотреть все ")]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        })
                      ]
                    }
                  )
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }