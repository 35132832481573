<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Заказать звонок',
    },
  },

  methods: {
    showPopup(ev) {
      globalThis.emitter.emit('showRequestCallback', ev);
    },
  },
};
</script>

<template>
  <span
    v-bind="$attrs"
    @click="showPopup"
    class="popup-button widget-link call-us-button nav-item header-icon__call-us header__nav-link header__nav-link--call-us"
  >
    {{ label }}
  </span>
</template>

<style lang="scss" src="./style.scss"></style>
