<script>
import debounce from 'lodash/debounce';
import Input from '../Input';

export default {
  inheritAttrs: false,

  props: {
    accessKeys: {
      type: Array,
      default: () => ['id', 'value'],
    },

    activeItem: {
      type: [Object, null],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
    },

    handler: {
      type: Function,
      default: () => [],
    },

    withDebounce: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Input,
  },

  data() {
    return {
      input: '',
    };
  },

  computed: {
    accessKey() {
      return this.accessKeys[0];
    },

    accessValue() {
      return this.accessKeys[1];
    },
  },

  methods: {
    reset() {
      this.input = '';
      this.$emit('select', null);
      this.$emit('search', []);

      setTimeout(() => {
        this.$refs.input.$el.value = this.input;
        this.$refs.input.$el.focus();
      }, 0);
    },

    onInput({ currentTarget }) {
      const { value } = currentTarget;

      this.input = value;

      if (!this.input) {
        this.$emit('select', null);
      }

      if (this.input) {
        if (this.withDebounce) {
          return this.debouncedHandler();
        }

        return this.wrappedHandler();
      }

      this.$emit('search', []);
    },

    onReset() {
      this.reset();
    },

    onClick(item) {
      this.$emit('search', []);
      this.$emit('select', item);
    },

    debouncedHandler: debounce(function() {
      return this.wrappedHandler();
    }, 250),

    async wrappedHandler() {
      try {
        const value = await this.handler(this.input);
        this.$emit('search', value);
      } catch (error) {
        console.error(error);
      }
    },
  },

  watch: {
    activeItem: {
      immediate: true,
      handler(value) {
        if (!value) {
          return this.reset();
        }

        this.input = value[this.accessValue];

        setTimeout(() => {
          this.$refs.input.$el.value = this.input;
        }, 0);
      },
    },
  },
};
</script>

<template>
  <div class="search-input">
    <Input v-bind="$attrs" ref="input" @input="onInput" />
    <div class="search-input__reset" @click="onReset" v-if="input">×</div>

    <ul v-if="items.length" class="search-input__menu">
      <li @click="onClick(item)" class="search-input__item" v-for="item in items" :key="item[accessKey]">
        {{ item[accessValue] }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
