var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-input" },
    [
      _c(
        "Input",
        _vm._b(
          { ref: "input", on: { input: _vm.onInput } },
          "Input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.input
        ? _c(
            "div",
            { staticClass: "search-input__reset", on: { click: _vm.onReset } },
            [_vm._v("×")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.items.length
        ? _c(
            "ul",
            { staticClass: "search-input__menu" },
            _vm._l(_vm.items, function(item) {
              return _c(
                "li",
                {
                  key: item[_vm.accessKey],
                  staticClass: "search-input__item",
                  on: {
                    click: function($event) {
                      return _vm.onClick(item)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(item[_vm.accessValue]) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }