var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.computedBody, {
    tag: "component",
    attrs: { catalogMenu: _vm.catalogMenu }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }