<template>
  <transition :name="transition">
    <div class="popup-mask" @click="$emit('close')">
      <div class="popup-wrapper" @click.stop>
        <div class="popup-container">
          <button class="vue-popup-close" @click="$emit('close')"></button>
          <div class="popup-body">
            <slot> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobilePopup',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'popup',
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    closeOnEscape(key) {
      if (key.key === 'Escape' || (key.keyCode !== undefined && key.keyCode === 27)) {
        if (this.value) {
          this.close();
        }
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        document.addEventListener('keyup', this.closeOnEscape);
      } else {
        document.removeEventListener('keyup', this.closeOnEscape);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-mask {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.popup-wrapper {
  display: table-cell;
  vertical-align: middle;
  bottom: 0;
  position: fixed;
  width: 100%;
  max-height: 100%;
}

.popup-container {
  position: relative;
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
  transition: all 0.3s ease;
  box-sizing: border-box;
  border-radius: 5px 5px 0 0;
  padding: 24px;

  .vue-popup-close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 40px;
    height: 40px;
    padding: 0;
    background-image: url(../../../assets/icons/close.svg);
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-position: center;

    @screen sm {
      top: 27px;
      right: 27px;
    }
    &:focus {
      outline: none;
    }
  }
}

.popup-body {
  text-align: center;
}

.popup-default-button {
  float: right;
}

.popup-enter {
  opacity: 0;
}

.popup-leave-active {
  opacity: 0;
}

.popup-enter .popup-container,
.popup-leave-active .popup-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.slide-enter-active .popup-container,
.slide-leave-active .popup-container {
  transition-duration: 0.3s;
}

.slide-enter-to .popup-container,
.slide-leave .popup-container {
  transform: translateY(0);
  opacity: 1;
}

.slide-enter-to,
.slide-leave {
  opacity: 1;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.slide-enter .popup-container,
.slide-leave-to .popup-container {
  transform: translateY(100%);
  opacity: 0;
}
</style>
