<script>
import { IMaskComponent } from 'vue-imask';

export default {
  components: {
    IMaskComponent,
  },

  props: {
    kind: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedComponent() {
      if (this.kind === 'with-mask') {
        return 'IMaskComponent';
      }

      return 'input';
    },
  },
};
</script>

<template>
  <component :is="computedComponent" v-bind="$attrs" v-on="$listeners" class="request-callback-popup__input" />
</template>

<style lang="scss" scoped src="./style.scss"></style>
