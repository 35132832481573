var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.currentCountryPhones, function(phone, i) {
      return _c(
        "a",
        {
          key: i,
          staticClass: "header__nav-link header__nav-link--phone",
          attrs: { href: _vm.computeHref(phone) }
        },
        [_vm._v("\n    " + _vm._s(phone) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }