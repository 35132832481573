var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _vm.isMain
        ? _c("Banner", {
            staticClass: "mobile-banner",
            attrs: { place: "top", banner: _vm.banner }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("MobileHeader", {
        attrs: {
          isCart: _vm.isCart,
          isMain: _vm.isMain,
          menuOpen: _vm.menuOpen
        },
        on: {
          "update:menuOpen": function($event) {
            _vm.menuOpen = $event
          },
          "update:menu-open": function($event) {
            _vm.menuOpen = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMain,
              expression: "!isMain"
            }
          ],
          staticClass: "mobile-fixed-header-wrapper mobile-fixed-header--show"
        },
        [
          _c("Banner", {
            staticClass: "mobile-banner",
            attrs: { place: "top", banner: _vm.banner }
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: ["mobile-fixed-header"] },
            [
              _c(
                "div",
                {
                  staticClass: "mobile-menu-block",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.menuOpen = true
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "mobile-menu-btn mobile-menu__button mobile-menu-toggler",
                      attrs: { href: "#" }
                    },
                    [_c("span", { staticClass: "middle-line" })]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "mobile-fixed-header__search",
                  attrs: {
                    action: "cart.php",
                    method: "get",
                    "accept-charset": "utf-8",
                    onsubmit: "javascript: return true;"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      staticClass: "form-params"
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "target",
                          value: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "action",
                          value: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "hidden", name: "mode", value: "search" }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "searchInSubcats",
                          value: "Y"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "including",
                          value: "all"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "mobile-fixed-header__search-input",
                    attrs: {
                      type: "text",
                      placeholder: "Поиск",
                      size: "30",
                      maxlength: "200",
                      name: "substring",
                      value: "",
                      title: "Поиск"
                    }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "mobile-fixed-header__search-submit",
                    attrs: { type: "submit" }
                  }),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "mobile-fixed-header__search-icon",
                      attrs: {
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z",
                          stroke: "currentColor",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M21 21L15.8 15.8",
                          stroke: "currentColor",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "mobile-fixed-header__geo-link-client-only-wrapper"
                },
                [
                  _c(
                    "ClientOnly",
                    [
                      _vm.clientInited
                        ? _c(
                            "Location",
                            {
                              staticClass:
                                "mobile-fixed-header__geo-link-wrapper"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "mobile-fixed-header__geo-link",
                                  attrs: { id: "WidgetCity" }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "mobile-fixed-header__geo-link-icon",
                                      attrs: {
                                        width: "11",
                                        height: "14",
                                        viewBox: "0 0 11 14",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M10 5.30769C10 8.89744 5.5 13 5.5 13C5.5 13 1 9.10256 1 5.30769C1 2.92862 3.01472 1 5.5 1C7.98528 1 10 2.92862 10 5.30769Z",
                                          stroke: "white",
                                          "stroke-width": "1.2",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("circle", {
                                        attrs: {
                                          cx: "5.5",
                                          cy: "5.5",
                                          r: "1.5",
                                          fill: "white",
                                          stroke: "white",
                                          "stroke-width": "1.2"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mobile-fixed-header__geo-link-text"
                                    },
                                    [_vm._v(_vm._s(_vm.currentCityName))]
                                  )
                                ]
                              )
                            ]
                          )
                        : _c("div", {
                            staticClass:
                              "mobile-fixed-header__geo-link-placeholder"
                          }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "mobile-fixed-header__geo-link-placeholder",
                        attrs: { slot: "placeholder" },
                        slot: "placeholder"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ClientOnly",
                [_vm.clientInited ? _c("HeaderBalance") : _vm._e()],
                1
              ),
              _vm._v(" "),
              _c("ContactUs", {
                staticClass: "mobile-fixed-header__call-us contact-us-toggler"
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("TapBar", { attrs: { isCart: _vm.isCart, isMain: _vm.isMain } }),
      _vm._v(" "),
      _c("MobileMenu", {
        attrs: {
          isCart: _vm.isCart,
          isMain: _vm.isMain,
          menuOpen: _vm.menuOpen
        },
        on: {
          "update:menuOpen": function($event) {
            _vm.menuOpen = $event
          },
          "update:menu-open": function($event) {
            _vm.menuOpen = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }