<script>
import SearchIcon from 'src/apps/header/assets/svg/search.inline.svg';

export default {
  components: { SearchIcon },
  data() {
    return {
      substring: null,
    };
  },
  methods: {
    onFormSubmit(e) {
      if (this.substring) {
        return true;
      }
      e.preventDefault();
    },
  },
};
</script>

<template>
  <form class="header__search" action="cart.php" method="get" accept-charset="utf-8" @submit="onFormSubmit">
    <div class="form-params" style="display: none;">
      <input type="hidden" name="target" value="search" />
      <input type="hidden" name="action" value="search" />
      <input type="hidden" name="mode" value="search" />
      <input type="hidden" name="searchInSubcats" value="Y" />
      <input type="hidden" name="including" value="all" />
    </div>

    <button type="submit" class="header__search-submit"></button>
    <SearchIcon class="header__search-icon" />

    <input
      class="header__search-input"
      type="text"
      placeholder="Поиск в онлайн-магазине оптики"
      size="30"
      maxlength="200"
      name="substring"
      v-model="substring"
      title="Поиск"
    />
  </form>
</template>
<style scoped>
.header__search-submit {
  border: none;
}

.header__search-input {
  border: none;
  padding: 8px 0;
  border-bottom: 1px solid #373737;
}
</style>
