<script>
import { mapGetters } from 'vuex';
import Default from './Default';
import Cart from './Cart';

export default {
  components: {
    Default,
    Cart,
  },

  computed: {
    ...mapGetters('header', ['isCartPage']),

    computeLayout() {
      return this.isCartPage ? 'Cart' : 'Default';
    },
  },
};
</script>

<template>
  <component :is="computeLayout" />
</template>

<style lang="scss" src="./style.scss"></style>
