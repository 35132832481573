var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header__medical-record-wrapper" }, [
    _c(
      "a",
      {
        staticClass: "header__medical-record",
        attrs: {
          href: _vm.medicalRecordLink,
          "aria-description": "Ссылка на страницу записи к врачу"
        }
      },
      [
        _c(
          "video",
          {
            key: _vm.src,
            staticClass: "header__medical-record-video",
            attrs: {
              autoplay: "",
              muted: "",
              playsinline: "",
              loop: "",
              width: "100%",
              height: "100%"
            },
            domProps: { muted: true }
          },
          _vm._l(_vm.videos, function(video) {
            return _c("source", {
              key: video.format,
              attrs: { src: _vm.src + "." + video.format, type: video.type }
            })
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }