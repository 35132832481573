<script>
import PhoneLinks from 'src/apps/header/components/PhoneLinks';
import RequestCallBack from 'src/apps/header/components/RequestCallBack';
import DoctorAppointment from 'src/apps/header/components/DoctorAppointment';
import HeaderBalance from 'src/apps/header/components/HeaderBalance';
import Profile from 'src/apps/header/components/Profile';
import Cart from 'src/apps/header/components/Cart';
import Location from 'src/apps/header/components/Location';
import Search from 'src/apps/header/components/Search';
import Categories from 'src/apps/header/components/Categories';
import Banner from 'src/components/Banner';
import LogoIcon from 'src/apps/header/assets/svg/logo.inline.svg';
import AroundTheClockIcon from 'src/apps/header/assets/svg/around-the-clock.inline.svg';
import { mapGetters } from 'vuex';
import ClientOnly from 'vue-client-only';

export default {
  components: {
    LogoIcon,
    PhoneLinks,
    RequestCallBack,
    DoctorAppointment,
    HeaderBalance,
    Profile,
    Cart,
    AroundTheClockIcon,
    Location,
    Search,
    Categories,
    ClientOnly,
    Banner,
  },

  computed: {
    ...mapGetters('header', ['isOtherPage', 'isMainPage', 'navigationMenu', 'clientInited', 'banner']),
  },
};
</script>

<template>
  <header class="header">
    <Banner place="top" :banner="banner" />
    <div class="header__container">
      <a :class="['header__logo-link', { 'header__logo-link--disabled': isMainPage }]" href="/">
        <LogoIcon class="header__logo-icon" />
      </a>

      <div class="header__wrapper">
        <div class="header__line header__line--first">
          <a v-for="(item, i) in navigationMenu" :key="i" class="header__nav-link" :href="item.link">{{ item.name }}</a>
          <ClientOnly>
            <Location v-if="clientInited" />
            <div v-else class="c-location-placeholder"></div>
            <div slot="placeholder" class="c-location-placeholder"></div>
          </ClientOnly>
        </div>

        <div class="header__line header__line--second">
          <Search />

          <div class="header__phone-wrapper">
            <AroundTheClockIcon class="header__phone-icon" />
            <PhoneLinks />
          </div>

          <RequestCallBack />

          <DoctorAppointment />

          <ClientOnly>
            <template v-if="clientInited">
              <HeaderBalance />
              <Profile />
              <Cart />
            </template>
            <div v-else class="c-profile-placeholder"></div>
            <div slot="placeholder" class="c-profile-placeholder"></div>
          </ClientOnly>
        </div>
      </div>
    </div>
    <Categories class="header__line-container" v-if="isOtherPage" />
  </header>
</template>

<style lang="scss" scoped src="./style.scss"></style>
