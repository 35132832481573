var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["c-checkbox", { error: _vm.error }] }, [
    _c("div", { staticClass: "c-checkbox__body", on: { click: _vm.onClick } }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "c-checkbox__mark",
        attrs: { src: _vm.markImage }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-checkbox__content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }