<script>
import HeaderBalance from 'src/apps/header/components/HeaderBalance';
import DoctorAppointment from 'src/apps/header/components/DoctorAppointment';
import MobileLogoCart from 'src/apps/header/assets/svg/mobile-logo-2.inline.svg';
import MobileLogoMain from 'src/apps/header/assets/svg/mobile-logo-1.inline.svg';
import Location from 'src/apps/header/components/Location';
import ContactUs from 'src/apps/header/components/ContactUs';
import { mapGetters } from 'vuex';
import ClientOnly from 'vue-client-only';

import PromoIcon from './icons/promo.inline.svg';
import SearchIcon from './icons/search.inline.svg';

export default {
  components: {
    MobileLogoCart,
    MobileLogoMain,
    HeaderBalance,
    DoctorAppointment,
    ClientOnly,
    Location,
    ContactUs,
    PromoIcon,
    SearchIcon,
  },

  props: {
    isCart: Boolean,
    isMain: Boolean,
    menuOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('profile', { profile: 'fields' }),
    ...mapGetters('header', ['clientInited']),

    currentCityName() {
      return this.profile.currentCityName;
    },

    computedLogo() {
      if (this.isCart) {
        return 'MobileLogoCart';
      }

      return 'MobileLogoMain';
    },
  },
};
</script>

<template>
  <div :class="['mobile-header', { 'mobile-header--main': isMain }]">
    <div class="mobile-header__column mobile-header__column--top">
      <div class="mobile-menu-block" @click.prevent="$emit('update:menuOpen', true)">
        <a class="mobile-menu-btn mobile-menu__button mobile-menu-toggler" href="#">
          <span class="middle-line"></span>
        </a>
      </div>
      <a :class="['mobile-header__logo-link', { 'mobile-header__logo-link--disabled': isMain }]" href="/">
        <component :is="computedLogo" />
      </a>

      <ContactUs class="mobile-header__call-us contact-us-toggler" />
    </div>
    <div class="mobile-header__column mobile-header__column_bottom">
      <div class="mobile-header__left">
        <ClientOnly>
          <HeaderBalance v-if="clientInited" />
          <Location v-if="clientInited" class="mobile-header__geo">
            <a class="mobile-header__link mobile-header__column--link" href="#" id="WidgetCity">
              <svg
                class="mobile-header__icon mobile-header__icon--geo"
                width="22"
                height="28"
                viewBox="0 0 22 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1111 10.6685C20.1111 17.8923 11.0555 26.1481 11.0555 26.1481C11.0555 26.1481 2 18.3051 2 10.6685C2 5.88104 6.0543 2 11.0555 2C16.0568 2 20.1111 5.88104 20.1111 10.6685Z"
                  stroke="white"
                  stroke-width="2.41481"
                  stroke-linejoin="round"
                />
                <circle cx="11.0555" cy="11.0556" r="3.01851" fill="white" stroke="white" stroke-width="2.41481" />
              </svg>

              <span class="mobile-header__link-text--geo">{{ currentCityName }}</span>
              <svg
                class="mobile-header__icon mobile-header__icon--arrow"
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.72682 10.5969C7.17214 10.1516 7.89416 10.1516 8.33948 10.5969L14.3751 16.6326L20.4107 10.5969C20.8561 10.1516 21.5781 10.1516 22.0234 10.5969C22.4687 11.0423 22.4687 11.7643 22.0234 12.2096L15.1814 19.0516C14.7361 19.4969 14.0141 19.4969 13.5688 19.0516L6.72682 12.2096C6.28149 11.7643 6.28149 11.0423 6.72682 10.5969Z"
                  fill="white"
                />
              </svg>
            </a>
          </Location>
          <div v-else class="mobile-header__link mobile-header__column--link mobile-header__link-placeholder"></div>
          <div
            slot="placeholder"
            class="mobile-header__link mobile-header__column--link mobile-header__link-placeholder"
          ></div>
        </ClientOnly>
      </div>
      <DoctorAppointment />
    </div>
    <form
      class="mobile-header__column mobile-header__column--search"
      action="cart.php"
      method="get"
      accept-charset="utf-8"
      onsubmit="javascript: return true;"
    >
      <div class="form-params" style="display: none;">
        <input type="hidden" name="target" value="search" />
        <input type="hidden" name="action" value="search" />
        <input type="hidden" name="mode" value="search" />
        <input type="hidden" name="searchInSubcats" value="Y" />
        <input type="hidden" name="including" value="all" />
      </div>
      <input
        class="mobile-header__search-input"
        type="text"
        placeholder="Поиск в онлайн-магазине оптики"
        size="30"
        maxlength="200"
        name="substring"
        value=""
        title="Поиск"
      />
      <button type="submit" class="mobile-header__search-submit"></button>
      <SearchIcon class="mobile-header__search-icon" />
    </form>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
