<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('profile', ['fields']),

    isLogged() {
      return !this.fields.isGuest;
    },

    firstName() {
      return this.fields.name;
    },

    url() {
      if (this.isLogged) {
        return `/myorders/`;
      }

      return `/?target=login`;
    },
  },
};
</script>

<template>
  <a class="header__nav-link header__nav-link--account" :href="url">
    <template v-if="isLogged">
      <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26 21.8064C26 19.5 23.2018 18.4193 19.75 18.4193H7.25C3 18.4193 1 20 1 21.8064M13.5002 13.1935C16.952 13.1935 19.7502 10.4639 19.7502 7.09677C19.7502 3.72962 16.952 1 13.5002 1C10.0485 1 7.25023 3.72962 7.25023 7.09677C7.25023 10.4639 10.0485 13.1935 13.5002 13.1935Z"
          stroke="#4F4F4F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ firstName }}
    </template>
    <svg v-else width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 29V24.8064C26 21.2789 23.2018 18.4193 19.75 18.4193H7.25C3.79822 18.4193 1 21.2789 1 24.8064V29M13.5002 13.1935C16.952 13.1935 19.7502 10.4639 19.7502 7.09677C19.7502 3.72962 16.952 1 13.5002 1C10.0485 1 7.25023 3.72962 7.25023 7.09677C7.25023 10.4639 10.0485 13.1935 13.5002 13.1935Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
</template>

<style lang="scss" src="./style.scss"></style>
