var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "Popup",
        {
          staticClass: "c-contact-us",
          on: {
            close: function($event) {
              return _vm.showContactUs(false)
            }
          }
        },
        [
          _c("h3", { staticClass: "contact-us-modal__title" }, [
            _vm._v("Связаться с нами")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "contact-us-modal__text" }, [
            _vm._v(
              "\n    Вы можете позвонить нам по телефону, а также отправить текстовое "
            ),
            _c("br"),
            _vm._v("или аудио-сообщение в\n    мессенджерах\n  ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "contact-us-modal__links contact-us-modal__links--social"
            },
            [
              _c(
                "a",
                {
                  staticClass: "contact-us-modal__link",
                  attrs: { target: "_blank", href: "https://wa.me/74952311001" }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "contact-us-modal__icon-link",
                      attrs: {
                        width: "49",
                        height: "49",
                        viewBox: "0 0 49 49",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
                        _c("path", {
                          attrs: {
                            d:
                              "M3.34595 45.8536L6.48895 34.3794C4.54995 31.0217 3.53028 27.2114 3.53145 23.3089C3.53728 11.0997 13.4726 1.16675 25.6829 1.16675C31.6084 1.16908 37.1688 3.47558 41.3513 7.66042C45.5326 11.8464 47.8356 17.4091 47.8333 23.3264C47.8286 35.5356 37.8909 45.4697 25.6829 45.4697C25.6818 45.4697 25.6829 45.4697 25.6829 45.4697H25.6736C21.9671 45.4686 18.3236 44.5387 15.0884 42.7736L3.34595 45.8536Z",
                            fill: "white"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M3.34601 46.4368C3.19201 46.4368 3.04267 46.3761 2.93184 46.2641C2.78601 46.1159 2.72884 45.9001 2.78367 45.7006L5.86251 34.4586C3.95384 31.0683 2.94701 27.2183 2.94934 23.3099C2.95401 10.7776 13.1518 0.583252 25.683 0.583252C31.7613 0.585585 37.4722 2.95275 41.7643 7.24842C46.0565 11.5453 48.419 17.2549 48.4167 23.3263C48.412 35.8574 38.213 46.0529 25.683 46.0529C21.9625 46.0518 18.2817 45.1336 15.015 43.3964L3.49417 46.4169C3.44517 46.4309 3.39617 46.4368 3.34601 46.4368Z",
                            fill: "white"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M25.6831 1.16667C31.6086 1.169 37.1689 3.4755 41.3514 7.66033C45.5328 11.8463 47.8358 17.409 47.8334 23.3263C47.8288 35.5355 37.8911 45.4697 25.6831 45.4697H25.6738C21.9673 45.4685 18.3238 44.5387 15.0886 42.7735L3.3461 45.8535L6.4891 34.3793C4.5501 31.0217 3.53044 27.2113 3.5316 23.3088C3.53744 11.0997 13.4728 1.16667 25.6831 1.16667ZM25.6831 0C12.8311 0 2.37077 10.4557 2.36493 23.3088C2.36377 27.237 3.35544 31.108 5.2361 34.5345L2.22027 45.5467C2.1106 45.9492 2.2226 46.3785 2.5166 46.6748C2.73827 46.8988 3.0381 47.0213 3.3461 47.0213C3.44527 47.0213 3.54444 47.0085 3.64244 46.9828L14.9439 44.0195C18.2433 45.7322 21.9416 46.6363 25.6738 46.6375C38.5351 46.6375 48.9954 36.1807 49.0013 23.3275C49.0036 17.0987 46.5804 11.242 42.1786 6.83667C37.7744 2.43017 31.9166 0.00233333 25.6831 0Z",
                            fill: "#CFD8DC"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M38.7052 10.304C35.2285 6.82498 30.6073 4.90815 25.6887 4.90698C15.534 4.90698 7.27518 13.1623 7.27051 23.31C7.26934 26.7878 8.24234 30.1735 10.0857 33.1053L10.5243 33.8018L8.66351 40.593L15.632 38.766L16.3052 39.165C19.1308 40.8426 22.3718 41.7293 25.6758 41.7305H25.6828C35.8305 41.7305 44.0893 33.474 44.0928 23.3251C44.094 18.4076 42.1818 13.783 38.7052 10.304Z",
                            fill: "#40C351"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M20.1459 14.0525C19.7318 13.1309 19.2954 13.1122 18.8999 13.0959C18.5768 13.0819 18.2081 13.083 17.8394 13.083C17.4708 13.083 16.8711 13.2219 16.3636 13.776C15.8561 14.3302 14.4258 15.6684 14.4258 18.3914C14.4258 21.1144 16.4091 23.7464 16.6856 24.115C16.9621 24.4837 20.5146 30.2505 26.1403 32.4695C30.8151 34.3129 31.7671 33.9465 32.7821 33.8544C33.7971 33.7622 36.0569 32.5162 36.5178 31.2235C36.9786 29.9309 36.9786 28.8237 36.8409 28.5927C36.7021 28.3617 36.3334 28.224 35.7804 27.9464C35.2274 27.6687 32.5056 26.3305 31.9981 26.1462C31.4906 25.9619 31.1219 25.8697 30.7521 26.4239C30.3834 26.9769 29.3229 28.224 28.9998 28.5927C28.6766 28.9625 28.3534 29.0092 27.8004 28.7315C27.2474 28.4539 25.4648 27.8705 23.3496 25.9852C21.7046 24.5187 20.5939 22.7069 20.2708 22.1527C19.9476 21.5997 20.2358 21.2999 20.5134 21.0234C20.7619 20.7749 21.0664 20.377 21.3441 20.0539C21.6206 19.7307 21.7128 19.4997 21.8971 19.131C22.0814 18.7612 21.9893 18.438 21.8504 18.1615C21.7139 17.8839 20.6383 15.1469 20.1459 14.0525Z",
                            fill: "white"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0" } }, [
                          _c("rect", {
                            attrs: { width: "49", height: "49", fill: "white" }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "contact-us-modal__link",
                  attrs: {
                    target: "_blank",
                    href: "https://telegram.me/ochkarik_opticbot"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "contact-us-modal__icon-link",
                      attrs: {
                        width: "48",
                        height: "48",
                        viewBox: "0 0 48 48",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z",
                          fill: "#039BE5"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "M10.982 23.4799L34.122 14.5579C35.196 14.1699 36.134 14.8199 35.786 16.4439L35.788 16.4419L31.848 35.0039C31.556 36.3199 30.774 36.6399 29.68 36.0199L23.68 31.5979L20.786 34.3859C20.466 34.7059 20.196 34.9759 19.576 34.9759L20.002 28.8699L31.122 18.8239C31.606 18.3979 31.014 18.1579 30.376 18.5819L16.634 27.2339L10.71 25.3859C9.42404 24.9779 9.39604 24.0999 10.982 23.4799Z",
                          fill: "white"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "contact-us-modal__text contact-us-modal__text--or"
            },
            [_vm._v("или")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "contact-us-modal__links contact-us-modal__links--column"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "contact-us-modal__link contact-us-modal__link--button",
                  attrs: { href: "tel:+78004444044" }
                },
                [
                  _vm._v("\n      Позвонить бесплатно"),
                  _c("br"),
                  _vm._v("\n      +7 (800) 444-40-44\n    ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "contact-us-modal__link contact-us-modal__link--button contact-us-modal__link--white call-us-button",
                  on: { click: _vm.onClickRequestCallback }
                },
                [
                  _vm._v("\n      Заказать обратный"),
                  _c("br"),
                  _vm._v("\n      звонок\n    ")
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }