<script>
import TapBar from 'src/apps/header/components/TapBar';
import MobileHeader from 'src/apps/header/components/MobileHeader';
import MobileMenu from 'src/apps/header/components/MobileMenu';
import HeaderBalance from 'src/apps/header/components/HeaderBalance';
import ClientOnly from 'vue-client-only';
import { mapGetters } from 'vuex';
import Location from 'src/apps/header/components/Location';
import ContactUs from 'src/apps/header/components/ContactUs';
import Banner from 'src/components/Banner';

export default {
  components: {
    TapBar,
    MobileHeader,
    MobileMenu,
    HeaderBalance,
    ClientOnly,
    Location,
    ContactUs,
    Banner,
  },

  props: {
    isCart: Boolean,
    isMain: Boolean,
  },

  data() {
    return {
      menuOpen: false,
    };
  },

  computed: {
    ...mapGetters('profile', { profile: 'fields' }),
    ...mapGetters('header', ['clientInited', 'banner']),

    currentCityName() {
      return this.profile.currentCityName;
    },
  },
};
</script>

<template>
  <fragment>
    <Banner class="mobile-banner" v-if="isMain" place="top" :banner="banner" />
    <MobileHeader :isCart="isCart" :isMain="isMain" :menuOpen.sync="menuOpen" />

    <div v-show="!isMain" class="mobile-fixed-header-wrapper mobile-fixed-header--show">
      <Banner class="mobile-banner" place="top" :banner="banner" />
      <div :class="['mobile-fixed-header']">
        <div class="mobile-menu-block" @click.prevent="menuOpen = true">
          <a class="mobile-menu-btn mobile-menu__button mobile-menu-toggler" href="#">
            <span class="middle-line"></span>
          </a>
        </div>
        <form
          class="mobile-fixed-header__search"
          action="cart.php"
          method="get"
          accept-charset="utf-8"
          onsubmit="javascript: return true;"
        >
          <div class="form-params" v-show="false">
            <input type="hidden" name="target" value="search" />
            <input type="hidden" name="action" value="search" />
            <input type="hidden" name="mode" value="search" />
            <input type="hidden" name="searchInSubcats" value="Y" />
            <input type="hidden" name="including" value="all" />
          </div>
          <input
            class="mobile-fixed-header__search-input"
            type="text"
            placeholder="Поиск"
            size="30"
            maxlength="200"
            name="substring"
            value=""
            title="Поиск"
          />
          <button type="submit" class="mobile-fixed-header__search-submit"></button>
          <svg
            class="mobile-fixed-header__search-icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M21 21L15.8 15.8" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </form>

        <div class="mobile-fixed-header__geo-link-client-only-wrapper">
          <ClientOnly>
            <Location v-if="clientInited" class="mobile-fixed-header__geo-link-wrapper">
              <span class="mobile-fixed-header__geo-link" id="WidgetCity">
                <svg
                  class="mobile-fixed-header__geo-link-icon"
                  width="11"
                  height="14"
                  viewBox="0 0 11 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 5.30769C10 8.89744 5.5 13 5.5 13C5.5 13 1 9.10256 1 5.30769C1 2.92862 3.01472 1 5.5 1C7.98528 1 10 2.92862 10 5.30769Z"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linejoin="round"
                  />
                  <circle cx="5.5" cy="5.5" r="1.5" fill="white" stroke="white" stroke-width="1.2" />
                </svg>
                <span class="mobile-fixed-header__geo-link-text">{{ currentCityName }}</span>
              </span>
            </Location>
            <div v-else class="mobile-fixed-header__geo-link-placeholder"></div>
            <div slot="placeholder" class="mobile-fixed-header__geo-link-placeholder"></div>
          </ClientOnly>
        </div>

        <ClientOnly>
          <HeaderBalance v-if="clientInited" />
        </ClientOnly>

        <ContactUs class="mobile-fixed-header__call-us contact-us-toggler" />
      </div>
    </div>

    <TapBar :isCart="isCart" :isMain="isMain" />
    <MobileMenu :isCart="isCart" :isMain="isMain" :menuOpen.sync="menuOpen" />
  </fragment>
</template>

<style lang="scss" src="./style.scss"></style>
