<script>
import { Vue } from 'src/apps/vue';
import MobilePopup from 'src/apps/checkout/components/MobilePopup.vue';
import { mapActions } from 'vuex';
import Popup from 'src/components/Popup';
import { geoAPI } from 'src/api';

let cancelTokenSource = '';

// Надо для того, чтобы инстансы компонента не дергали API каждый раз при маунте.
const cachedFastCities = new Promise(resolve => {
  if (!globalThis.window) {
    return resolve([]);
  }

  geoAPI.citiesPresence().then(data => resolve(data));
});

export default {
  components: {
    Popup,
    MobilePopup,
  },

  props: {
    callback: {
      type: Function,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    needMobile: {
      type: Boolean,
      default: false,
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
    mobileHidden: {
      type: Boolean,
      default: false,
    },
    modifyProfile: {
      type: Boolean,
      default: true,
    },
    cityName: {
      type: String,
      default: '',
    },
    cityNamePrefix: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchValue: '',
    isInputActive: false,
    isMobile: false,
    isCitySelection: false,
    xid: '',
    cities: [],
    citiesList: [],
    fastCities: [],
  }),
  computed: {
    showPopup() {
      const show = this.isMobile && this.isCitySelection;
      if (show) {
        document.body.style.overflow = 'hidden';
      }
      return show;
    },

    currentCity() {
      return this.cityName || this.$store.getters['profile/fields'].currentCityName;
    },
  },
  methods: {
    ...mapActions('profile', ['setProfile']),

    handleResize() {
      this.isMobile = window.innerWidth < 640;
    },

    closePopup() {
      this.isCitySelection = false;
      document.body.style.overflow = 'auto';
    },

    async changeCity(id) {
      await this.setNewCity(id);
      this.searchValue = '';

      await this.callback(id);
      this.closePopup();
    },
    async loadCities(evt) {
      const options = {
        c: 'RU',
        q: evt.target.value,
        mode: 'none',
      };
      await this.getCities(options);
    },

    showCitySelection() {
      this.isCitySelection = true;
      document.body.style.overflow = 'hidden';
    },

    async getCities(params = {}) {
      const { c = 'RU', q = '', mode = 'none' } = params;

      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }

      cancelTokenSource = Vue.$http.CancelToken.source();

      const payload = { c, q, mode };

      this.cities = await geoAPI.cities(payload, { cancelToken: cancelTokenSource.token });
    },

    async setNewCity(id) {
      const params = {
        currentCityId: id,
      };

      if (this.modifyProfile) {
        await this.setProfile(params);
      }
    },

    handleSlot(opts = {}) {
      const { action } = opts;

      if (!this.$slots.default) {
        return;
      }

      if (!action) {
        throw new Error('please specify "action" param');
      }

      const target = this.$slots.default[0];

      if (action === 'register') {
        target?.elm.addEventListener('click', this.showCitySelection);
      } else if (action === 'unregister') {
        target?.elm.removeEventListener('click', this.showCitySelection);
      }
    },
  },

  async mounted() {
    this.fastCities = await cachedFastCities;

    this.handleResize();
    this.handleSlot({ action: 'register' });

    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    this.handleSlot({ action: 'unregister' });
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<template>
  <div>
    <slot>
      <div class="delivery__header" :class="{ 'delivery__need-mobile': needMobile, 'delivery__in-header': inHeader }">
        <svg
          class="delivery__icon"
          @click.prevent="showCitySelection"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 8.4C19 13.0392 11 21 11 21C11 21 3 13.0392 3 8.4C3 3 6.0908 0 11 0C15.9092 0 19 3 19 8.4Z"
            fill="#00aafa"
          />
          <circle cx="11" cy="8" r="4" fill="white" />
        </svg>
        <h2 class="delivery__title delivery__title--pointer" :class="{ mobile__hidden: mobileHidden }">
          <span v-if="title" class="delivery__text delivery__text-description">{{ title }}</span>

          <span class="delivery__text-city-wrapper">
            <span v-if="cityNamePrefix" class="delivery__text delivery__text--prefix">{{ cityNamePrefix }}</span>
            <button class="delivery__text--button" @click.prevent="showCitySelection">
              <span class="delivery__text delivery__text--blue delivery__text--city">{{ currentCity }}</span>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L5 5L1 1" stroke="#373737" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </span>
        </h2>
      </div>
    </slot>

    <MobilePopup @close="closePopup" :transition="'slide'" v-if="showPopup">
      <div class="city-selection-popup--mobile">
        <h3 class="city-selection-popup__title">Выбор города</h3>
        <div class="city-selection-popup__input-wrapper">
          <input class="city-selection-popup__input" name="city" v-model="searchValue" @input="loadCities" />
          <label class="city-selection-popup__placeholder" for="city" v-if="!searchValue">{{ currentCity }}</label>
          <label class="city-selection-popup__closeBtn" v-if="searchValue" @click="searchValue = ''"></label>
        </div>

        <ul class="city-selection-popup__default-cities" v-if="!searchValue">
          <li
            class="city-selection-popup__default-city"
            @click="changeCity(city.id)"
            v-for="city in fastCities"
            :key="city.id"
          >
            {{ city.name }}
          </li>
        </ul>

        <ul class="city-selection-popup__city-dadata" v-else>
          <li @click="changeCity(city.id)" v-for="city in cities" :key="city.id">
            <span>{{ city.name }}</span>
            <span>{{ city.fullName }}</span>
          </li>
        </ul>
      </div>
    </MobilePopup>

    <Popup class="c-city-selection" @close="closePopup" v-if="!isMobile && isCitySelection">
      <div class="city-selection-popup">
        <h3 class="city-selection-popup__title">Выбор города</h3>

        <div class="city-selection-popup__input-wrapper">
          <input
            class="city-selection-popup__input"
            name="city"
            v-model="searchValue"
            @input="loadCities"
            @focus="isInputActive = true"
            @blur="isInputActive = false"
          />
          <label
            class="city-selection-popup__placeholder"
            :class="{ 'city-selection-popup__placeholder--active': searchValue }"
            for="city"
            >{{ isInputActive || searchValue ? 'Ваш город' : currentCity }}</label
          >
          <label class="city-selection-popup__closeBtn" v-if="searchValue" @click="searchValue = ''"></label>
        </div>

        <ul class="city-selection-popup__default-cities" v-if="!searchValue">
          <li
            v-for="city in fastCities"
            :key="city.id"
            class="city-selection-popup__default-city"
            @click="changeCity(city.id)"
          >
            {{ city.name }}
          </li>
        </ul>

        <ul class="city-selection-popup__city-dadata" v-else>
          <li v-for="city in cities" :key="city.id" @click="changeCity(city.id)">
            <span>{{ city.name }}</span>
            <span>{{ city.fullName }}</span>
          </li>
        </ul>
      </div>
    </Popup>
  </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>
