import { menuAPI, bannerAPI } from 'src/api';
import { init } from 'src/apps/init';

export default {
  namespaced: true,

  state: () => ({
    inited: false,
    clientInited: false,
    navigationMenu: [],
    catalogMenu: [],
    serviceMenu: [],
    otherMenu: [],
    target: '',
    banner: {},
  }),

  getters: {
    inited: state => state.inited,
    clientInited: state => state.clientInited,
    navigationMenu: state => state.navigationMenu,
    catalogMenu: state => state.catalogMenu,
    serviceMenu: state => state.serviceMenu,
    otherMenu: state => state.otherMenu,
    banner: state => state.banner,

    isMainPage: state => state.target === 'main',
    isCartPage: state => state.target === 'cart',
    isOtherPage: state => state.target === 'other',
  },

  actions: {
    async init({ commit, getters }, opts = {}) {
      const { target, url } = opts;

      if (getters.inited) {
        return;
      }

      const bannerPayload = {
        place: 'top',
        params: {
          currentUrl: url,
        },
      };

      const [navigationMenu, catalogMenu, serviceMenu, otherMenu, banner = {}] = await Promise.all([
        menuAPI.getMenu({ type: 'web_header_top' }),
        menuAPI.getMenu({ type: 'catalog_sections_tree' }),
        menuAPI.getMenu({ type: 'web_adaptive_services' }),
        menuAPI.getMenu({ type: 'web_adaptive' }),
        bannerAPI.getBanner(bannerPayload),
      ]);

      commit('setNavigationMenu', navigationMenu);
      commit('setCatalogMenu', catalogMenu);
      commit('setServiceMenu', serviceMenu);
      commit('setOtherMenu', otherMenu);
      commit('setTarget', target);
      commit('setBanner', banner);

      commit('setInited', true);
    },

    async clientInit({ commit }) {
      await init.wait();
      commit('setClientInited', true);
    },
  },

  mutations: {
    setInited(state, value) {
      state.inited = value;
    },

    setClientInited(state, value) {
      state.clientInited = value;
    },

    setTarget(state, value) {
      state.target = value;
    },

    setNavigationMenu(state, value) {
      state.navigationMenu = value;
    },

    setCatalogMenu(state, value) {
      state.catalogMenu = value;
    },

    setServiceMenu(state, value) {
      state.serviceMenu = value;
    },

    setOtherMenu(state, value) {
      state.otherMenu = value;
    },

    setBanner(state, value) {
      state.banner = value;
    },
  },
};
