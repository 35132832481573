<script>
import 'src/pages/myacuvue/header';

import { mapActions, mapGetters } from 'vuex';
import { init } from 'src/apps/header/utils/init';
import store from 'src/apps/header/store';

import Static from 'src/apps/header/components/Static';
import Fixed from 'src/apps/header/components/Fixed';
import Popups from 'src/apps/header/components/Popups';
import TopMessages from 'src/components/TopMessages';
import { ANALYTICS } from 'src/constants';
import MyAcuvue from 'src/components/MyAcuvue';
import Preloader from 'src/components/Preloader';

export default {
  props: {
    target: {
      type: String,
      default: '',
      validator(value) {
        return ['main', 'cart', 'other'].includes(value);
      },
    },
  },

  components: {
    Fixed,
    Popups,
    Static,
    TopMessages,
    MyAcuvue,
    Preloader,
  },

  computed: {
    ...mapGetters('header', ['inited']),
    ...mapGetters('profile', { profile: 'fields' }),

    canShow() {
      return this.inited;
    },

    isSticky() {
      return this.target !== 'main';
    },
  },

  methods: {
    ...mapActions('header', ['init', 'clientInit']),
    ...mapActions('cart', ['fetchCart']),
    handleResize() {
      this.$store.commit('common/setIsTablet', window.innerWidth < 1280);
      this.$store.commit('common/setIsMobile', window.innerWidth < 640);
    },

    closePopupMya() {
      globalThis.emitter.emit('showAuthMyAcuvuePopup', { isShow: false, target: '' });
    },
  },

  created() {
    this.$store.registerModule('header', store, {
      preserveState: typeof window !== 'undefined',
    });
  },

  destroyed() {
    this.$store.unregisterModule('header');
    window.removeEventListener('resize', this.handleResize);
  },

  async serverPrefetch() {
    await this.init({ target: this.target, url: this.$route.fullPath });
  },

  mounted() {
    globalThis.emitter.on('fetchCart', this.fetchCart);
    init();
    this.clientInit();

    window.onloadAttach(() => {
      if (this.target === 'other') {
        window.xcore.trigger(ANALYTICS.OTHER_PAGE);
      }
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  beforeDestroy() {
    globalThis.emitter.off('fetchCart', this.fetchCart);
  },
};
</script>

<template>
  <div
    vue-app-id="header"
    v-bind="$props"
    :class="{ 'mobile-header-sticky': isSticky }"
    id="regionId"
    :data-id="profile.currentCityId"
  >
    <template v-if="canShow">
      <Static />
      <Fixed />
      <Popups />
      <TopMessages />
      <MyAcuvue @close="closePopupMya" />
      <Preloader />
    </template>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
