<script>
import { mapGetters } from 'vuex';
import Categories from 'src/apps/header/components/Categories';
import PromotionEventsIcon from 'src/apps/header/assets/svg/promotion-events.inline.svg';
import AboutIcon from './icons/about.inline.svg';
import BlogIcon from './icons/blog.inline.svg';
import DostavkaRFIcon from './icons/dostavka-rf.inline.svg';
import DoctorIcon from './icons/med-record.inline.svg';
import NewsIcon from './icons/news.inline.svg';
import ObmenIVozvratIcon from './icons/obmen-i-vozvrat.inline.svg';
import OplataIcon from './icons/oplata.inline.svg';
import PunktyVidachyIcon from './icons/punkty-vidachy.inline.svg';
import SalonyOptikiIcon from './icons/salony-optiki.inline.svg';

export default {
  components: {
    AboutIcon,
    BlogIcon,
    DostavkaRFIcon,
    DoctorIcon,
    NewsIcon,
    ObmenIVozvratIcon,
    OplataIcon,
    PromotionEventsIcon,
    PunktyVidachyIcon,
    SalonyOptikiIcon,
    Categories,
  },

  props: {
    menuOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('header', ['serviceMenu', 'otherMenu']),
  },

  methods: {
    getIcon(name) {
      switch (name) {
        case 'blog':
          return 'BlogIcon';

        case 'about':
          return 'AboutIcon';

        case 'dostavka-rf':
          return 'DostavkaRFIcon';

        case 'doctor':
          return 'DoctorIcon';

        case 'news':
          return 'NewsIcon';

        case 'obmen-i-vozvrat':
          return 'ObmenIVozvratIcon';

        case 'oplata':
          return 'OplataIcon';

        case 'promotion-events':
          return 'PromotionEventsIcon';

        case 'punkty-vidachy':
          return 'PunktyVidachyIcon';

        case 'salony-optiki':
          return 'SalonyOptikiIcon';

        default:
          return false;
      }
    },

    isDoctorItem(item) {
      return item.class === 'doctor';
    },
  },
};
</script>

<template>
  <fragment>
    <div class="mobile-menu js-mobile-menu-container" :class="menuOpen ? 'open' : 'close'">
      <div class="mobile-menu__overlay">
        <svg
          @click.prevent="$emit('update:menuOpen', false)"
          class="mobile-menu__close"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.62964 1.745L21.2295 22.1086" stroke="white" stroke-width="1.6" stroke-linecap="round" />
          <path d="M21.3702 1.745L1.77034 22.1086" stroke="white" stroke-width="1.6" stroke-linecap="round" />
        </svg>
      </div>
      <div class="mobile-menu__content">
        <Categories :isAdaptive="true" />

        <div class="mobile-menu__divider"></div>

        <div class="menu-specials-block">
          <div v-for="(item, i) in serviceMenu" :key="i" class="mobile-menu__item">
            <a class="mobile-menu__item-link" :href="item.link">
              <component :is="getIcon(item.class)" />
              {{ item.name }}
            </a>
          </div>
        </div>

        <div class="mobile-menu__divider"></div>

        <div v-for="(item, i) in otherMenu" :key="i" class="mobile-menu__item">
          <a
            :class="[
              'mobile-menu__item-link',
              {
                'med-record-button': isDoctorItem(item),
                'popup-button': isDoctorItem(item),
              },
            ]"
            :href="item.link"
          >
            <component :is="getIcon(item.class)" />
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" src="./style.scss"></style>
