<script>
const MYA_ERROR_TEXT = {
  blocked: 'Профиль заблокирован',
  offline: 'Требуется вход',
};

export default {
  props: {
    balance: Number,
    isMyacuvueUser: Boolean,
    personalUrl: String,
    error: String,
    isPending: Boolean,
  },
  computed: {
    errorText() {
      if (this.error) {
        return MYA_ERROR_TEXT[this.error];
      }
      return '';
    },
  },
};
</script>

<template>
  <div class="acuvue-bonus">
    <a :class="[{ 'acuvue-bonus__link--error': error }, 'acuvue-bonus__link']" :href="personalUrl">
      <svg
        v-if="isPending"
        class="acuvue-bonus__icon acuvue-bonus__icon--arrows"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.52083 6.29167H1.77083C1.54982 6.29167 1.33786 6.20387 1.18158 6.04759C1.0253 5.89131 0.9375 5.67935 0.9375 5.45833V1.70833C0.9375 1.48732 1.0253 1.27536 1.18158 1.11908C1.33786 0.962797 1.54982 0.875 1.77083 0.875C1.99185 0.875 2.20381 0.962797 2.36009 1.11908C2.51637 1.27536 2.60417 1.48732 2.60417 1.70833V4.625H5.52083C5.74185 4.625 5.95381 4.7128 6.11009 4.86908C6.26637 5.02536 6.35417 5.23732 6.35417 5.45833C6.35417 5.67935 6.26637 5.89131 6.11009 6.04759C5.95381 6.20387 5.74185 6.29167 5.52083 6.29167Z"
          fill="#002F86"
        />
        <path
          d="M15.835 10.042C15.6139 10.042 15.402 9.9542 15.2457 9.79792C15.0894 9.64164 15.0016 9.42968 15.0016 9.20866C15.0023 7.74161 14.5187 6.31537 13.6259 5.15125C12.7331 3.98713 11.481 3.15024 10.064 2.77043C8.64696 2.39062 7.14418 2.48914 5.78886 3.0507C4.43354 3.61226 3.30146 4.60545 2.5683 5.87616C2.45757 6.06745 2.27539 6.20692 2.06183 6.26388C1.84827 6.32084 1.62083 6.29064 1.42955 6.17991C1.23826 6.06918 1.09879 5.887 1.04183 5.67345C0.984863 5.45989 1.01507 5.23245 1.1258 5.04116C2.04267 3.45303 3.45804 2.21187 5.15231 1.51023C6.84658 0.808603 8.72504 0.685721 10.4963 1.16065C12.2675 1.63558 13.8325 2.68178 14.9485 4.13694C16.0644 5.59209 16.669 7.37486 16.6683 9.20866C16.6683 9.42968 16.5805 9.64164 16.4242 9.79792C16.2679 9.9542 16.056 10.042 15.835 10.042ZM14.8975 17.542C14.6764 17.542 14.4645 17.4542 14.3082 17.2979C14.1519 17.1416 14.0641 16.9297 14.0641 16.7087V13.792H11.1475C10.9264 13.792 10.7145 13.7042 10.5582 13.5479C10.4019 13.3916 10.3141 13.1797 10.3141 12.9587C10.3141 12.7376 10.4019 12.5257 10.5582 12.3694C10.7145 12.2131 10.9264 12.1253 11.1475 12.1253H14.8975C15.1185 12.1253 15.3304 12.2131 15.4867 12.3694C15.643 12.5257 15.7308 12.7376 15.7308 12.9587V16.7087C15.7308 16.9297 15.643 17.1416 15.4867 17.2979C15.3304 17.4542 15.1185 17.542 14.8975 17.542Z"
          fill="#002F86"
        />
        <path
          d="M8.33333 17.5417C6.12401 17.539 4.00593 16.6602 2.4437 15.098C0.88147 13.5357 0.00264643 11.4177 0 9.20833C0 8.98732 0.0877973 8.77536 0.244078 8.61908C0.400358 8.4628 0.61232 8.375 0.833333 8.375C1.05435 8.375 1.26631 8.4628 1.42259 8.61908C1.57887 8.77536 1.66667 8.98732 1.66667 9.20833C1.66603 10.6754 2.14963 12.1016 3.04242 13.2657C3.93521 14.4299 5.18727 15.2668 6.6043 15.6466C8.02134 16.0264 9.52411 15.9279 10.8794 15.3663C12.2348 14.8047 13.3668 13.8115 14.1 12.5408C14.1548 12.4461 14.2278 12.3631 14.3147 12.2966C14.4016 12.2301 14.5007 12.1813 14.6065 12.1531C14.7122 12.1249 14.8225 12.1178 14.931 12.1322C15.0394 12.1466 15.144 12.1823 15.2388 12.2371C15.3335 12.2919 15.4165 12.3649 15.483 12.4518C15.5495 12.5387 15.5983 12.6378 15.6265 12.7435C15.6547 12.8493 15.6618 12.9596 15.6474 13.068C15.633 13.1765 15.5973 13.2811 15.5425 13.3758C14.8096 14.6396 13.7583 15.6892 12.4934 16.4201C11.2285 17.151 9.79421 17.5377 8.33333 17.5417Z"
          fill="#002F86"
        />
      </svg>

      <svg
        :class="['acuvue-bonus__icon', { 'acuvue-bonus__icon--pending': isPending }]"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24.75" height="24.75" rx="3" fill="#002F86" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.1758 17.6719H15.895L14.515 14.5287H10.2441L8.82039 17.7891H6.96094L11.3166 8.12867C11.3166 8.12867 11.4787 7.69199 11.3605 7.44213C11.2423 7.19227 10.9678 7.10577 10.9678 7.10577V6.57422H13.1204L18.1758 17.6719ZM12.2769 9.51386L10.8215 13.1088H13.8765L12.2769 9.51386Z"
          fill="white"
        />
        <g clip-path="url(#clip0)">
          <path
            d="M20.1706 6.29911C21.1207 6.29911 21.8909 5.52888 21.8909 4.57875C21.8909 3.62863 21.1207 2.8584 20.1706 2.8584C19.2204 2.8584 18.4502 3.62863 18.4502 4.57875C18.4502 5.52888 19.2204 6.29911 20.1706 6.29911Z"
            stroke="white"
            stroke-width="0.2"
          />
          <path
            d="M20.1607 3.96066H19.9082V5.2734H20.1607V4.62825C20.1607 4.41507 20.1943 4.16262 20.4664 4.16262C20.5253 4.16262 20.5758 4.17664 20.6207 4.21591L20.7357 3.9859C20.674 3.94663 20.601 3.92139 20.5281 3.92139C20.3598 3.92139 20.2308 4.01956 20.1691 4.17384H20.1607V3.96066Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="4.2075" height="4.2075" fill="white" transform="translate(18.0674 2.4751)" />
          </clipPath>
        </defs>
      </svg>
      <div v-if="isMyacuvueUser" :class="[{ 'acuvue-bonus__tooltip--error': error }, 'acuvue-bonus__tooltip']">
        <template v-if="errorText">
          {{ errorText }}
        </template>
        <template v-else>
          <p class="acuvue-bonus__tooltip-text acuvue-bonus__tooltip-text--title">Баланс</p>
          <p class="acuvue-bonus__tooltip-text acuvue-bonus__tooltip-text--balance">{{ balance }} баллов</p>
        </template>
      </div>
    </a>
  </div>
</template>
