var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "header__line header__line--nav-links header-nav" },
    _vm._l(_vm.catalogMenu, function(category, i) {
      return _c(
        "li",
        {
          key: "desktop-" + i,
          class: ["header-nav__item", "header-nav__item--" + category.class]
        },
        [
          _c(
            "a",
            { staticClass: "header-nav__link", attrs: { href: category.link } },
            [
              category.image_header
                ? _c("img", {
                    staticClass: "header-nav__link-img",
                    attrs: { src: category.image_header, alt: category.name }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "header-nav__link-text" }, [
                _vm._v(_vm._s(category.name))
              ])
            ]
          ),
          _vm._v(" "),
          category.children.length
            ? _c(
                "ul",
                { staticClass: "header-nav__inner-list" },
                [
                  _vm._l(
                    (_vm.groups = _vm.getLocalAvailableGroups(
                      category.children,
                      category.name
                    )),
                    function(group, i) {
                      return _vm._l(group.children, function(child, ii) {
                        return _c(
                          "li",
                          {
                            key: "desktop-" + i + "-" + ii,
                            staticClass: "header-nav__inner-item"
                          },
                          [
                            group.name
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "header-nav__inner-item-title header-nav__inner-item-title--upper"
                                  },
                                  [
                                    _vm.shouldRenderGroupName(
                                      group.children,
                                      ii
                                    )
                                      ? [_vm._v(_vm._s(group.name))]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "header-nav__inner-item-links",
                                  {
                                    "header-nav__inner-item-links--bordered": _vm.isLastGroup(
                                      _vm.groups,
                                      i
                                    )
                                  }
                                ]
                              },
                              [
                                _vm._l(child, function(subcategory) {
                                  return _c(
                                    "a",
                                    {
                                      key: subcategory.name,
                                      staticClass:
                                        "header-nav__inner-item-link",
                                      attrs: {
                                        href: subcategory.link,
                                        onClick: "return true"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(subcategory.name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "header-nav__inner-item-see-all",
                                    attrs: { href: category.link }
                                  },
                                  [_vm._v("Смотреть все")]
                                )
                              ],
                              2
                            )
                          ]
                        )
                      })
                    }
                  )
                ],
                2
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }