<script>
import Icon from './icon.inline.svg';

export default {
  components: {
    Icon,
  },

  methods: {
    onClick() {
      globalThis.emitter.emit('showContactUs', true);
    },
  },
};
</script>

<template>
  <Icon @click="onClick" />
</template>
