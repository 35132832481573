var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("MobileHeader", {
        attrs: {
          isCart: _vm.isCart,
          isMain: _vm.isMain,
          menuOpen: _vm.menuOpen
        },
        on: {
          "update:menuOpen": function($event) {
            _vm.menuOpen = $event
          },
          "update:menu-open": function($event) {
            _vm.menuOpen = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mobile-fixed-header" },
        [
          _c(
            "div",
            {
              staticClass: "mobile-menu-block",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.menuOpen = true
                }
              }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "mobile-menu-btn mobile-menu__button mobile-menu-toggler"
                },
                [_c("span", { staticClass: "middle-line" })]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "mobile-fixed-header__logo-link",
              attrs: { href: "/" }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "mobile-fixed-header__logo",
                  attrs: {
                    width: "100",
                    height: "32",
                    viewBox: "0 0 230 69",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M206.36 46.6761L217.289 34.6011H211.03L200.995 46.1687V34.6011H196.127L196.027 60.1717H200.995V47.9952L218.78 68.8981H225.139L206.36 46.6761Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M107.306 34.5004H101.047L91.0117 46.068V34.3989H86.1433L86.0439 60.071H91.0117V47.8945L108.796 68.696L115.155 68.7975L96.3769 46.5754L107.306 34.5004Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M128.368 34.5007V36.4287C126.182 34.7037 123.4 33.689 120.419 33.689C113.067 33.689 107.105 39.7772 107.105 47.286C107.105 54.7948 113.067 60.8831 120.419 60.8831C123.4 60.8831 126.182 59.8684 128.368 58.2449V59.9698H133.435V34.3993L128.368 34.5007ZM120.419 56.114C115.65 56.114 111.875 52.1566 111.875 47.3875C111.875 42.5169 115.749 38.661 120.419 38.661C125.188 38.661 128.964 42.6184 128.964 47.3875C128.964 52.2581 125.089 56.114 120.419 56.114Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M150.721 33.79C147.74 33.79 144.958 34.8047 142.772 36.5297V34.5003H137.705V68.7974H142.772V58.3459C144.958 60.0709 147.74 61.0856 150.721 61.0856C158.073 61.0856 164.034 54.9974 164.134 47.4886C164.034 39.8783 158.073 33.79 150.721 33.79ZM150.721 56.215C145.952 56.215 142.176 52.2577 142.176 47.4886C142.176 42.618 146.051 38.7621 150.721 38.7621C155.49 38.7621 159.265 42.7195 159.265 47.59C159.265 52.2577 155.39 56.215 150.721 56.215Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M75.6096 36.9353C75.3115 41.5015 71.6354 45.0529 67.065 45.0529C66.6676 45.0529 66.3695 45.0529 65.9721 44.9515C66.9656 41.6029 67.5618 38.1529 67.5618 34.5C67.5618 15.525 52.4597 0 33.7809 0C15.2014 0 0 15.4235 0 34.5C0 53.5765 15.102 69 33.7809 69C47.0945 69 58.6198 61.0853 64.1837 49.6191C65.0779 49.8221 66.0714 49.9235 67.065 49.9235C70.3437 49.9235 73.3244 48.7059 75.6096 46.7779V60.0706H80.6767V34.3985H75.6096V36.9353ZM33.7809 64.1294C17.7846 64.1294 4.76907 50.8368 4.76907 34.5C4.86842 18.1632 17.7846 4.87059 33.7809 4.87059C49.7771 4.87059 62.7927 18.1632 62.6933 34.5C62.6933 37.4426 62.2959 40.2838 61.5011 42.9221C59.7127 41.2985 58.5204 38.9647 58.5204 36.3265V34.3985H53.7514V36.3265C53.7514 41.0956 56.1359 45.2559 59.7127 47.6912C54.9436 57.4324 45.1074 64.1294 33.7809 64.1294Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M186.191 49.4159C185.993 53.2718 182.813 56.4173 178.938 56.4173C174.964 56.4173 171.685 53.0688 171.685 49.01C171.685 49.01 171.685 49.1115 171.685 34.7026H167.016C167.016 48.7056 167.016 49.01 167.016 49.01C167.016 55.8085 172.281 61.1865 178.938 61.1865C181.72 61.1865 184.204 60.2732 186.191 58.6497V60.3747H191.159V34.7026H186.191V49.4159Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M224.039 31.0145V28.8035H224.909L226.2 31.0145H227.204L225.68 28.5649C226.379 28.2721 226.728 27.7812 226.728 27.0922C226.728 26.552 226.561 26.1482 226.226 25.8807C225.892 25.6131 225.376 25.4793 224.68 25.4793H223.157V31.0145H224.039ZM224.651 28.0538H224.039V26.2441H224.621C225.022 26.2441 225.322 26.3123 225.521 26.4486C225.721 26.5849 225.82 26.8095 225.82 27.1225C225.82 27.4304 225.728 27.6626 225.544 27.8191C225.359 27.9756 225.062 28.0538 224.651 28.0538Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M230 28.4299C230 31.2847 227.745 33.5991 224.963 33.5991C222.182 33.5991 219.927 31.2847 219.927 28.4299C219.927 25.5751 222.182 23.2607 224.963 23.2607C227.745 23.2607 230 25.5751 230 28.4299ZM229.16 28.4299C229.16 30.8089 227.281 32.7375 224.963 32.7375C222.645 32.7375 220.766 30.8089 220.766 28.4299C220.766 26.0509 222.645 24.1223 224.963 24.1223C227.281 24.1223 229.16 26.0509 229.16 28.4299Z",
                      fill: "white"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "ClientOnly",
            [_vm.clientInited ? _c("HeaderBalance") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c("ContactUs", {
            staticClass:
              "mobile-fixed-header__call-us contact-us-toggler contact-us-card"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("TapBar", { attrs: { isCart: _vm.isCart, isMain: _vm.isMain } }),
      _vm._v(" "),
      _c("MobileMenu", {
        attrs: {
          isCart: _vm.isCart,
          isMain: _vm.isMain,
          menuOpen: _vm.menuOpen
        },
        on: {
          "update:menuOpen": function($event) {
            _vm.menuOpen = $event
          },
          "update:menu-open": function($event) {
            _vm.menuOpen = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }