<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      countryCodes: {
        RU: ['+7 (800) 444-40-44'],
        KZ: ['8-10-7-4956626459', '8-10-800-555-695-90'],
        BY: ['8-10-800-555-695-90'],
        AM: ['8-10-7-4956626459'],
        KG: ['8-10-800-555-695-90'],
      },
    };
  },

  computed: {
    ...mapGetters('profile', ['fields']),

    countryCode() {
      return this.fields.countryCode;
    },

    currentCountryPhones() {
      return this.countryCodes[this.countryCode];
    },
  },

  methods: {
    computeHref(phone) {
      return `tel:${phone}`;
    },
  },
};
</script>

<template>
  <div>
    <a
      v-for="(phone, i) in currentCountryPhones"
      :key="i"
      class="header__nav-link header__nav-link--phone"
      :href="computeHref(phone)"
    >
      {{ phone }}
    </a>
  </div>
</template>
