var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(_vm.componentName, {
        tag: "component",
        attrs: {
          balance: _vm.balance,
          isMyacuvueUser: _vm.isMyacuvueUser,
          personalUrl: _vm.personalUrl,
          error: _vm.myacuvueError,
          isPending: _vm.isPending
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }