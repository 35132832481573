<script>
import { isMobile } from 'src/utils/is-mobile';
import { mapGetters } from 'vuex';
import Mobile from './Mobile';
import Desktop from './Desktop';

const PROMO = {
  myacuvue: 'ma',
};

export default {
  components: { Mobile, Desktop },

  data() {
    return {
      show: false,
      componentName: 'Desktop',
    };
  },

  computed: {
    ...mapGetters('profile', ['fields']),
    ...mapGetters('myAcuvue', ['balance', 'isLock']),

    isMyacuvueUser() {
      const found = this.fields?.promo?.findIndex(p => p.name === PROMO.myacuvue);
      return found !== -1;
    },

    promoData() {
      if (this.isMyacuvueUser) {
        return this.fields?.promo?.find(p => p.name === PROMO.myacuvue);
      }
      return null;
    },

    myacuvueError() {
      if (this.promoData) {
        return this.promoData.lastErrorType;
      }
      return '';
    },

    personalUrl() {
      return '/personal_myacuvue/';
    },

    isPending() {
      return this.isLock && !this.myacuvueError;
    },
  },

  methods: {
    init() {
      if (isMobile()) {
        this.componentName = 'Mobile';
      }

      this.show = true;
    },
  },

  mounted() {
    this.init();
    window.addEventListener('resize', this.init);
  },

  destroyed() {
    window.removeEventListener('resize', this.init);
  },
};
</script>

<template>
  <component
    v-if="show"
    :is="componentName"
    :balance="balance"
    :isMyacuvueUser="isMyacuvueUser"
    :personalUrl="personalUrl"
    :error="myacuvueError"
    :isPending="isPending"
  />
</template>

<style lang="scss" src="src/features/myacuvue/widgets/balance/style.scss"></style>
