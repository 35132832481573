<script>
import MenuArrowRightIcon from 'src/apps/header/assets/svg/menu-arrow-right.inline.svg';
import { getAvailableGroups } from 'src/utils/categories';

export default {
  components: {
    MenuArrowRightIcon,
  },

  props: {
    catalogMenu: Array,
    getAvailableGroups: Function,
  },

  methods: {
    getLocalAvailableGroups(children) {
      return getAvailableGroups(children, { limit: -1 });
    },

    showSeeAll(name) {
      return name === 'Бренды' || name === 'Все бренды';
    },
  },
};
</script>

<template>
  <ul class="header__line header__line--nav-links header-nav">
    <template v-for="(category, i) in catalogMenu">
      <div :key="`adaptive-${i}`" class="mobile-menu__item mobile-menu-open-category">
        <a
          class="mobile-menu__item-link mobile-menu__item-link--uppercase"
          :class="{ 'mobile-menu__item-link--no-img': !category.image_header }"
          :href="category.link"
        >
          <img
            v-if="category.image_header"
            :src="category.image_header"
            :alt="category.name"
            class="mobile-menu__item-link-img"
          />
          <span
            class="mobile-menu__item-link-text"
            :class="{
              'mobile-menu__item-link-text--no-category': !category.children.length,
            }"
          >
            {{ category.name }}
          </span>
        </a>
        <MenuArrowRightIcon v-if="category.children.length" />
      </div>

      <div
        v-if="category.children.length"
        :key="i"
        :class="['mobile-menu__submenu', 'js-accorgion-body', `mobile-menu__submenu--${category.class}`]"
      >
        <template v-for="({ name, children }, ii) in getLocalAvailableGroups(category.children)">
          <span :key="`adaptive-${i}-${ii}`" class="mobile-menu__submenu-title">{{ name }}</span>

          <div v-for="(child, index) in children" :key="`children-${index}`">
            <a
              v-for="subcategory in child"
              :key="subcategory.name"
              class="mobile-menu__submenu-link"
              :href="subcategory.link"
              >{{ subcategory.name }}</a
            >
            <a class="mobile-menu__submenu-see-all" v-if="showSeeAll(name)" :href="category.link">Смотреть все </a>
          </div>
        </template>
      </div>
    </template>
  </ul>
</template>
