var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "header__nav-link header__nav-link--relative",
      attrs: { href: "/cart/", "data-header": "header" }
    },
    [
      _c(
        "svg",
        {
          staticClass: "header__nav-link-icon",
          attrs: {
            width: "25",
            height: "32",
            viewBox: "0 0 25 32",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M5.92857 13.1829L6.92857 5.04025C6.92857 3.24143 8.39964 1.7832 10.2143 1.7832H14.7857C16.6004 1.7832 18.0714 3.24143 18.0714 5.04025L19.0714 13.1829M2 8.29729L1 26.7539C1 28.5527 2.47106 30.0109 4.28571 30.0109H20.7143C22.5289 30.0109 24 28.5527 24 26.7539L23 8.29729H2Z",
              stroke: "currentColor",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          })
        ]
      ),
      _vm._v(" "),
      _vm.hasItems
        ? _c("span", { staticClass: "header__nav-link-counter cart-counter" }, [
            (_vm.totalCartItems || _vm.totalAmount) > 99
              ? _c("span", [_vm._v("99+")])
              : _c("span", [
                  _vm.totalAmount === 0
                    ? _c("span", [_vm._v(_vm._s(_vm.totalCartItems))])
                    : _c("span", [_vm._v(_vm._s(_vm.totalAmount))])
                ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }