<script>
import { mapGetters } from 'vuex';

import Adaptive from './Adaptive';
import Desktop from './Desktop';

export default {
  props: {
    isAdaptive: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Adaptive,
    Desktop,
  },

  computed: {
    ...mapGetters('header', ['catalogMenu']),

    computedBody() {
      if (this.isAdaptive) {
        return 'Adaptive';
      }

      return 'Desktop';
    },
  },
};
</script>

<template>
  <component :is="computedBody" :catalogMenu="catalogMenu" />
</template>
