<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <span v-if="value" class="request-callback-popup__error">{{ value }}</span>
</template>

<style lang="scss" scoped src="./style.scss"></style>
