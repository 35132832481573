function showBodyScroll(show) {
  if (show) {
    document.body.classList.remove('noScroll');
    return;
  }
  document.body.classList.add('noScroll');
}

function toggleMobileMenu() {
  const mobileMenuToggleButtons = document.querySelectorAll('.mobile-menu-toggler');
  const mobileMenu = document.querySelector('.mobile-menu');
  const overlay = document.querySelector('.mobile-menu__overlay');
  mobileMenuToggleButtons.forEach(element => {
    element.addEventListener('click', evt => {
      if (!mobileMenu.classList.contains('open')) {
        evt.preventDefault();
        mobileMenu.classList.add('open');
        mobileMenu.classList.remove('close');
        showBodyScroll();
      }
    });
  });

  overlay.addEventListener('click', evt => {
    evt.preventDefault();
    showBodyScroll(true);
  });
}

function initMobileTapMenu() {
  const showTapBarMenuButtons = document.querySelectorAll('.showTapBarMenu');
  const tapBarMenu = document.getElementById('tapBarMenu');
  const tapBarMenuOverlay = document.querySelector('.tap-bar-menu__overlay');

  document.addEventListener('click', evt => {
    if (evt.target.classList.contains('showTapBarMenu')) {
      evt.preventDefault();
      tapBarMenu.classList.toggle('tap-bar-menu--active');
      tapBarMenuOverlay.classList.toggle('open');
      if (tapBarMenu.classList.contains('tap-bar-menu--active')) {
        showBodyScroll();
      } else {
        showBodyScroll(true);
      }
      showTapBarMenuButtons.forEach(element => {
        element.classList.toggle('active');
      });
    } else if (
      tapBarMenu &&
      !evt.composedPath().includes(tapBarMenu) &&
      tapBarMenu.classList.contains('tap-bar-menu--active')
    ) {
      tapBarMenu.classList.remove('tap-bar-menu--active');
      tapBarMenuOverlay.classList.remove('open');
      showTapBarMenuButtons.forEach(element => {
        element.classList.remove('active');
      });
      if (evt.target.classList.contains('mobile-menu-toggler')) {
        return;
      }
      showBodyScroll(true);
    }
  });
}

function initFixedHeader() {
  const mobileHeader = document.querySelector('.mobile-header');
  const mobileFixedHeader = document.querySelector('.mobile-fixed-header');
  const tapBarMenu = document.getElementById('tapBarMenu');

  if (tapBarMenu) {
    const bottomMenu = 65;
    if (mobileHeader.getBoundingClientRect().bottom > 0) {
      tapBarMenu.style.height = `calc(100% - ${mobileHeader.getBoundingClientRect().bottom + bottomMenu}px)`;
    } else if (mobileFixedHeader.getBoundingClientRect().bottom > 0) {
      tapBarMenu.style.height = `calc(100% - ${mobileFixedHeader.getBoundingClientRect().bottom + bottomMenu}px)`;
    } else {
      tapBarMenu.style.height = `calc(100% - ${bottomMenu}px)`;
    }
  }
}

function fixedHeaderHandler() {
  document.addEventListener('scroll', () => {
    initFixedHeader();
  });
  window.addEventListener('resize', () => {
    initFixedHeader();
  });
}

function initBurgerMenuContent() {
  const burgerMenuContent = document.querySelector('.mobile-menu__content');
  burgerMenuContent.addEventListener('click', evt => {
    if (evt.target.classList.contains('mobile-menu-open-category')) {
      if (evt.target.classList.contains('open')) {
        burgerMenuContent.classList.remove('noScroll');
        evt.target.classList.remove('open');
        evt.target.nextElementSibling.classList.remove('open');
        return;
      }
      evt.target.classList.add('open');
      evt.target.nextElementSibling.classList.add('open');
      burgerMenuContent.classList.add('noScroll');
    }
  });
}

function initBurgerMenuSwiper() {
  const burgerMenuContent = document.querySelector('.mobile-menu__content');
  let startCoordX = 0;
  let shift = 0;
  function touchStartHandler(startEvt) {
    startCoordX = startEvt.touches[0].clientX;
    burgerMenuContent.style.transition = 'none';
    burgerMenuContent.style.overflow = 'hidden';
  }
  function touchMoveHandler(moveEvt) {
    shift += moveEvt.touches[0].clientX - startCoordX;
    if (shift <= 0) {
      burgerMenuContent.style.transform = `translateX(0) translate3d(${shift}px, 0, 0)`;
    }
    startCoordX = moveEvt.touches[0].clientX;
  }
  function touchEndHandler() {
    const shiftPercents = (shift / window.innerWidth) * 100;
    burgerMenuContent.style.transform = null;
    burgerMenuContent.style.transition = null;
    burgerMenuContent.style.overflow = null;

    if (shiftPercents < -30) {
      const mobileMenu = document.querySelector('.mobile-menu');
      mobileMenu.classList.toggle('close');
      mobileMenu.classList.toggle('open');
      showBodyScroll(true);
    }
    startCoordX = 0;
    shift = 0;
  }
  burgerMenuContent.addEventListener('touchstart', touchStartHandler);
  burgerMenuContent.addEventListener('touchmove', touchMoveHandler);
  document.addEventListener('touchend', touchEndHandler);
}

const init = () => {
  toggleMobileMenu();
  initMobileTapMenu();
  initFixedHeader();
  fixedHeaderHandler();
  initBurgerMenuContent();
  initBurgerMenuSwiper();
};

export { init };
