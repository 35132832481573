var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.transition } }, [
    _c(
      "div",
      {
        staticClass: "popup-mask",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "popup-wrapper",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "popup-container" }, [
              _c("button", {
                staticClass: "vue-popup-close",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "popup-body" }, [_vm._t("default")], 2)
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }