<script>
import { getLocalAvailableGroups } from 'src/utils/categories';

export default {
  props: {
    catalogMenu: Array,
  },

  data() {
    return {
      icons: {
        'Контактные линзы': 'LensesIcon',
        'Солнцезащитные очки': 'GlassesIcon',
        Оправы: 'FramesIcon',
        'Сопутствующие товары': 'HelperItemsIcon',
        'Подарочная карта': 'GiftCardIcon',
      },
    };
  },

  methods: {
    computeIcon(name) {
      return this.icons[name];
    },

    isLastGroup(groups, i) {
      return groups.length - 1 !== i;
    },

    shouldRenderGroupName(children, i) {
      return children.length <= 1 || i === 0;
    },

    getLocalAvailableGroups,
  },
};
</script>

<template>
  <ul class="header__line header__line--nav-links header-nav">
    <li
      v-for="(category, i) in catalogMenu"
      :key="`desktop-${i}`"
      :class="['header-nav__item', `header-nav__item--${category.class}`]"
    >
      <a class="header-nav__link" :href="category.link">
        <img
          v-if="category.image_header"
          :src="category.image_header"
          :alt="category.name"
          class="header-nav__link-img"
        />
        <span class="header-nav__link-text">{{ category.name }}</span>
      </a>

      <ul v-if="category.children.length" class="header-nav__inner-list">
        <template v-for="(group, i) in (groups = getLocalAvailableGroups(category.children, category.name))">
          <li v-for="(child, ii) in group.children" :key="`desktop-${i}-${ii}`" class="header-nav__inner-item">
            <div v-if="group.name" class="header-nav__inner-item-title header-nav__inner-item-title--upper">
              <template v-if="shouldRenderGroupName(group.children, ii)">{{ group.name }}</template>
            </div>

            <div
              :class="[
                'header-nav__inner-item-links',
                {
                  'header-nav__inner-item-links--bordered': isLastGroup(groups, i),
                },
              ]"
            >
              <a
                v-for="subcategory in child"
                :key="subcategory.name"
                class="header-nav__inner-item-link"
                :href="subcategory.link"
                onClick="return true"
              >
                {{ subcategory.name }}
              </a>
              <a class="header-nav__inner-item-see-all" :href="category.link">Смотреть все</a>
            </div>
          </li>
        </template>
      </ul>
    </li>
  </ul>
</template>

<style lang="scss" src="./style.scss"></style>
