<script>
import LogoIcon from 'src/apps/header/assets/svg/logo3.inline.svg';
import LogoIconCart from 'src/apps/header/assets/svg/logo.inline.svg';
import ContactUsIcon from 'src/apps/header/assets/svg/contact-us.inline.svg';
import { mapGetters } from 'vuex';
import Cart from './Cart';
import Default from './Default';

export default {
  components: {
    LogoIcon,
    LogoIconCart,
    Cart,
    Default,
    ContactUsIcon,
  },

  computed: {
    ...mapGetters('header', ['isMainPage', 'isCartPage', 'isOtherPage']),

    computedMainPart() {
      return this.isCartPage ? 'Cart' : 'Default';
    },
  },
};
</script>

<template>
  <component :is="computedMainPart" :isMain="isMainPage" :isCart="isCartPage" />
</template>

<style lang="scss" src="./style.scss"></style>
