var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "header__container header__container--cart" }, [
      _c(
        "a",
        {
          staticClass: "header__logo-link header__logo-link--cart",
          attrs: { href: "/" }
        },
        [_c("LogoIcon")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "header__wrapper" }, [
        _c(
          "div",
          { staticClass: "header__line" },
          [
            _c(
              "a",
              {
                staticClass: "header__link-catalog",
                attrs: { href: "/contaktnie-linzy/" }
              },
              [_vm._v("Каталог товаров")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "header__phone-wrapper header__phone-wrapper--cart"
              },
              [
                _c("AroundTheClockIcon", { staticClass: "header__phone-icon" }),
                _vm._v(" "),
                _c("PhoneLinks")
              ],
              1
            ),
            _vm._v(" "),
            _c("RequestCallBack", {
              staticClass: "header__nav-link--cart",
              attrs: { label: "Обратиться в поддержку" }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }