<script>
import { URL_MEDICAL_RECORD } from 'src/constants/backend';

const VIDEO_SRC = {
  BLUE: 'frontend-new/assets/video/btn-blue',
  PINK: 'frontend-new/assets/video/btn-pink',
};

const VIDEOS = [
  {
    format: 'webm',
    type: `video/webm; codecs="vp8, VP9, AV1, vorbis"`,
  },
  {
    format: 'mp4',
    type: `video/mp4; codecs="avc1.42E01E, mp4a.40.2"`,
  },
  {
    format: 'ogg',
    type: `video/ogg; codecs="theora, vorbis"`,
  },
];

export default {
  props: {
    medicalRecordLink: {
      type: String,
      default: URL_MEDICAL_RECORD,
    },
  },
  data() {
    return {
      src: VIDEO_SRC.BLUE,
      videos: VIDEOS,
    };
  },
  methods: {
    resize() {
      this.src = window.innerWidth >= 1280 ? VIDEO_SRC.BLUE : VIDEO_SRC.PINK;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
};
</script>

<template>
  <div class="header__medical-record-wrapper">
    <a class="header__medical-record" :href="medicalRecordLink" aria-description="Ссылка на страницу записи к врачу">
      <video class="header__medical-record-video" :key="src" autoplay muted playsinline loop width="100%" height="100%">
        <source v-for="video of videos" :key="video.format" :src="`${src}.${video.format}`" :type="video.type" />
      </video>
    </a>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>
