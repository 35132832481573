var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "header__nav-link header__nav-link--account",
      attrs: { href: _vm.url }
    },
    [
      _vm.isLogged
        ? [
            _c(
              "svg",
              {
                attrs: {
                  width: "27",
                  height: "23",
                  viewBox: "0 0 27 23",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M26 21.8064C26 19.5 23.2018 18.4193 19.75 18.4193H7.25C3 18.4193 1 20 1 21.8064M13.5002 13.1935C16.952 13.1935 19.7502 10.4639 19.7502 7.09677C19.7502 3.72962 16.952 1 13.5002 1C10.0485 1 7.25023 3.72962 7.25023 7.09677C7.25023 10.4639 10.0485 13.1935 13.5002 13.1935Z",
                    stroke: "#4F4F4F",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                })
              ]
            ),
            _vm._v("\n    " + _vm._s(_vm.firstName) + "\n  ")
          ]
        : _c(
            "svg",
            {
              attrs: {
                width: "27",
                height: "30",
                viewBox: "0 0 27 30",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M26 29V24.8064C26 21.2789 23.2018 18.4193 19.75 18.4193H7.25C3.79822 18.4193 1 21.2789 1 24.8064V29M13.5002 13.1935C16.952 13.1935 19.7502 10.4639 19.7502 7.09677C19.7502 3.72962 16.952 1 13.5002 1C10.0485 1 7.25023 3.72962 7.25023 7.09677C7.25023 10.4639 10.0485 13.1935 13.5002 13.1935Z",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }
              })
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }