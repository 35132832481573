const handlerName = 'click-outside-handler';
const attributeName = 'data-click-outside-id';

const clickOutside = {
  bind(el, binding) {
    if (el[handlerName]) {
      return;
    }

    const handler = function(ev) {
      if (typeof binding.value !== 'function') {
        return;
      }

      const { target } = ev;

      if (!target) {
        return binding.value();
      }

      const id = el.getAttribute(attributeName);

      // Если элемент является элементом с директивой или элемент является ребёнком элемента с директивой
      if (target === el || target.closest(`[${attributeName}="${id}"]`)) {
        return false;
      }

      return binding.value();
    };

    // eslint-disable-next-line no-param-reassign
    el[handlerName] = el[handlerName] || handler;
    el.setAttribute(attributeName, Math.floor(Math.random() * 1000 + 1));
    document.addEventListener('click', el[handlerName]);
  },

  unbind(el) {
    if (!el[handlerName]) return;

    document.removeEventListener('click', el[handlerName]);

    // eslint-disable-next-line no-param-reassign
    delete el[handlerName];
  },
};

export { clickOutside };
