var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c("Banner", { attrs: { place: "top", banner: _vm.banner } }),
      _vm._v(" "),
      _c("div", { staticClass: "header__container" }, [
        _c(
          "a",
          {
            class: [
              "header__logo-link",
              { "header__logo-link--disabled": _vm.isMainPage }
            ],
            attrs: { href: "/" }
          },
          [_c("LogoIcon", { staticClass: "header__logo-icon" })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header__wrapper" }, [
          _c(
            "div",
            { staticClass: "header__line header__line--first" },
            [
              _vm._l(_vm.navigationMenu, function(item, i) {
                return _c(
                  "a",
                  {
                    key: i,
                    staticClass: "header__nav-link",
                    attrs: { href: item.link }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              _vm._v(" "),
              _c(
                "ClientOnly",
                [
                  _vm.clientInited
                    ? _c("Location")
                    : _c("div", { staticClass: "c-location-placeholder" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "c-location-placeholder",
                    attrs: { slot: "placeholder" },
                    slot: "placeholder"
                  })
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header__line header__line--second" },
            [
              _c("Search"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header__phone-wrapper" },
                [
                  _c("AroundTheClockIcon", {
                    staticClass: "header__phone-icon"
                  }),
                  _vm._v(" "),
                  _c("PhoneLinks")
                ],
                1
              ),
              _vm._v(" "),
              _c("RequestCallBack"),
              _vm._v(" "),
              _c("DoctorAppointment"),
              _vm._v(" "),
              _c(
                "ClientOnly",
                [
                  _vm.clientInited
                    ? [
                        _c("HeaderBalance"),
                        _vm._v(" "),
                        _c("Profile"),
                        _vm._v(" "),
                        _c("Cart")
                      ]
                    : _c("div", { staticClass: "c-profile-placeholder" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "c-profile-placeholder",
                    attrs: { slot: "placeholder" },
                    slot: "placeholder"
                  })
                ],
                2
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isOtherPage
        ? _c("Categories", { staticClass: "header__line-container" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }