<script>
import PhoneLinks from 'src/apps/header/components/PhoneLinks';
import RequestCallBack from 'src/apps/header/components/RequestCallBack';

import LogoIcon from 'src/apps/header/assets/svg/logo2.inline.svg';
import AroundTheClockIcon from 'src/apps/header/assets/svg/around-the-clock.inline.svg';

export default {
  components: {
    LogoIcon,
    PhoneLinks,
    AroundTheClockIcon,
    RequestCallBack,
  },
};
</script>

<template>
  <header class="header">
    <div class="header__container header__container--cart">
      <a class="header__logo-link header__logo-link--cart" href="/">
        <LogoIcon />
      </a>

      <div class="header__wrapper">
        <div class="header__line">
          <a href="/contaktnie-linzy/" class="header__link-catalog">Каталог товаров</a>

          <div class="header__phone-wrapper header__phone-wrapper--cart">
            <AroundTheClockIcon class="header__phone-icon" />
            <PhoneLinks />
          </div>

          <RequestCallBack label="Обратиться в поддержку" class="header__nav-link--cart" />
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" src="./style.scss"></style>
