var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: [
        "acuvue-link",
        { "acuvue-link--pending": _vm.isPending },
        { "acuvue-link--error": _vm.error }
      ],
      attrs: { href: _vm.personalUrl }
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "37",
            height: "37",
            viewBox: "0 0 37 37",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M31.1037 0H5.89628C2.63986 0 0 2.63986 0 5.89628V31.1037C0 34.3601 2.63986 37 5.89628 37H31.1037C34.3601 37 37 34.3601 37 31.1037V5.89628C37 2.63986 34.3601 0 31.1037 0Z",
              fill: "#223979"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M27.4285 27.0471L20.0828 10.8652L19.9505 10.5715H16.6247V11.4628L16.8527 11.6107C17.2272 11.8538 17.4776 12.1333 17.0155 13.2941L10.8829 27.0491L10.5715 27.75H13.3681L13.4964 27.4442L15.4157 22.9024H22.1528L24.1007 27.4482L24.2309 27.75H27.7501L27.4305 27.0471H27.4285ZM21.186 20.6598H16.3642L18.7558 14.9958L21.184 20.6598H21.186Z",
              fill: "white"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M24.586 11.2706C24.0525 10.7469 23.7858 10.1047 23.7858 9.34399C23.7858 8.58323 24.0525 7.94103 24.586 7.40752C25.1196 6.874 25.7716 6.60724 26.5324 6.60724C27.2932 6.60724 27.9452 6.874 28.4788 7.40752C29.0123 7.94103 29.279 8.59311 29.279 9.34399C29.279 10.0949 29.0123 10.7469 28.4788 11.2706C27.9452 11.7942 27.2932 12.061 26.5324 12.061C25.7716 12.061 25.1196 11.7942 24.586 11.2706ZM24.8923 7.7138C24.4378 8.16827 24.2205 8.71167 24.2205 9.34399C24.2205 9.97631 24.4477 10.5296 24.9022 10.9742C25.3567 11.4287 25.9001 11.6559 26.5423 11.6559C27.1845 11.6559 27.7279 11.4287 28.1725 10.9742C28.6269 10.5197 28.8542 9.97631 28.8542 9.34399C28.8542 8.71167 28.627 8.15839 28.1824 7.7138C27.7279 7.25932 27.1845 7.03208 26.5423 7.03208C25.9001 7.03208 25.3567 7.25932 24.9022 7.7138H24.8923ZM25.4555 10.8754V7.79283H26.5423C26.8683 7.79283 27.1351 7.87187 27.3524 8.02007C27.5698 8.16827 27.6884 8.39551 27.6884 8.70179C27.6884 9.15627 27.4512 9.43291 26.977 9.53171L27.8069 10.8853H27.1351L26.4138 9.60087H26.0186V10.8853H25.4654L25.4555 10.8754ZM26.0088 9.13651H26.4534C26.9078 9.13651 27.1252 8.97843 27.1252 8.68203C27.1252 8.39551 26.9177 8.25719 26.5126 8.25719H26.0088V9.13651Z",
              fill: "white"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }