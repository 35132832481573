<script>
import ContactUs from './ContactUs';
import RequestCallback from './RequestCallBack';

export default {
  components: {
    ContactUs,
    RequestCallback,
  },

  data() {
    return {};
  },
};
</script>

<template>
  <span>
    <ContactUs />
    <RequestCallback />
  </span>
</template>
