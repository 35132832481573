var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CitySelection",
    _vm._b(
      { attrs: { callback: _vm.onChangeCity } },
      "CitySelection",
      _vm.$props,
      false
    ),
    [
      _vm._t("default", function() {
        return [
          _c(
            "span",
            { staticClass: "header__nav-link header__nav-link--city" },
            [
              _c("MapPinIcon", {
                staticClass:
                  "header__nav-link-icon header__nav-link-icon--map-pin"
              }),
              _vm._v("\n      " + _vm._s(_vm.currentCityName) + "\n      "),
              _c("ArrowDownIcon", {
                staticClass:
                  "header__nav-link-icon header__nav-link-icon--arrow"
              })
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }