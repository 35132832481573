var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["mobile-header", { "mobile-header--main": _vm.isMain }] },
    [
      _c(
        "div",
        { staticClass: "mobile-header__column mobile-header__column--top" },
        [
          _c(
            "div",
            {
              staticClass: "mobile-menu-block",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("update:menuOpen", true)
                }
              }
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              class: [
                "mobile-header__logo-link",
                { "mobile-header__logo-link--disabled": _vm.isMain }
              ],
              attrs: { href: "/" }
            },
            [_c(_vm.computedLogo, { tag: "component" })],
            1
          ),
          _vm._v(" "),
          _c("ContactUs", {
            staticClass: "mobile-header__call-us contact-us-toggler"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mobile-header__column mobile-header__column_bottom" },
        [
          _c(
            "div",
            { staticClass: "mobile-header__left" },
            [
              _c(
                "ClientOnly",
                [
                  _vm.clientInited ? _c("HeaderBalance") : _vm._e(),
                  _vm._v(" "),
                  _vm.clientInited
                    ? _c("Location", { staticClass: "mobile-header__geo" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "mobile-header__link mobile-header__column--link",
                            attrs: { href: "#", id: "WidgetCity" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "mobile-header__icon mobile-header__icon--geo",
                                attrs: {
                                  width: "22",
                                  height: "28",
                                  viewBox: "0 0 22 28",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M20.1111 10.6685C20.1111 17.8923 11.0555 26.1481 11.0555 26.1481C11.0555 26.1481 2 18.3051 2 10.6685C2 5.88104 6.0543 2 11.0555 2C16.0568 2 20.1111 5.88104 20.1111 10.6685Z",
                                    stroke: "white",
                                    "stroke-width": "2.41481",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _vm._v(" "),
                                _c("circle", {
                                  attrs: {
                                    cx: "11.0555",
                                    cy: "11.0556",
                                    r: "3.01851",
                                    fill: "white",
                                    stroke: "white",
                                    "stroke-width": "2.41481"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "mobile-header__link-text--geo" },
                              [_vm._v(_vm._s(_vm.currentCityName))]
                            ),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "mobile-header__icon mobile-header__icon--arrow",
                                attrs: {
                                  width: "29",
                                  height: "28",
                                  viewBox: "0 0 29 28",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M6.72682 10.5969C7.17214 10.1516 7.89416 10.1516 8.33948 10.5969L14.3751 16.6326L20.4107 10.5969C20.8561 10.1516 21.5781 10.1516 22.0234 10.5969C22.4687 11.0423 22.4687 11.7643 22.0234 12.2096L15.1814 19.0516C14.7361 19.4969 14.0141 19.4969 13.5688 19.0516L6.72682 12.2096C6.28149 11.7643 6.28149 11.0423 6.72682 10.5969Z",
                                    fill: "white"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    : _c("div", {
                        staticClass:
                          "mobile-header__link mobile-header__column--link mobile-header__link-placeholder"
                      }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "mobile-header__link mobile-header__column--link mobile-header__link-placeholder",
                    attrs: { slot: "placeholder" },
                    slot: "placeholder"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("DoctorAppointment")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mobile-header__column mobile-header__column--search",
          attrs: {
            action: "cart.php",
            method: "get",
            "accept-charset": "utf-8",
            onsubmit: "javascript: return true;"
          }
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("input", {
            staticClass: "mobile-header__search-input",
            attrs: {
              type: "text",
              placeholder: "Поиск в онлайн-магазине оптики",
              size: "30",
              maxlength: "200",
              name: "substring",
              value: "",
              title: "Поиск"
            }
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "mobile-header__search-submit",
            attrs: { type: "submit" }
          }),
          _vm._v(" "),
          _c("SearchIcon", { staticClass: "mobile-header__search-icon" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "mobile-menu-btn mobile-menu__button mobile-menu-toggler",
        attrs: { href: "#" }
      },
      [_c("span", { staticClass: "middle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-params", staticStyle: { display: "none" } },
      [
        _c("input", {
          attrs: { type: "hidden", name: "target", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "action", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "mode", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "searchInSubcats", value: "Y" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "including", value: "all" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }