var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        class: { "mobile-header-sticky": _vm.isSticky },
        attrs: {
          "vue-app-id": "header",
          id: "regionId",
          "data-id": _vm.profile.currentCityId
        }
      },
      "div",
      _vm.$props,
      false
    ),
    [
      _vm.canShow
        ? [
            _c("Static"),
            _vm._v(" "),
            _c("Fixed"),
            _vm._v(" "),
            _c("Popups"),
            _vm._v(" "),
            _c("TopMessages"),
            _vm._v(" "),
            _c("MyAcuvue", { on: { close: _vm.closePopupMya } }),
            _vm._v(" "),
            _c("Preloader")
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }