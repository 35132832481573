<script>
import MapPinIcon from 'src/apps/header/assets/svg/map-pin.inline.svg';
import ArrowDownIcon from 'src/apps/header/assets/svg/arrow-down.inline.svg';
import { mapGetters } from 'vuex';
import CitySelection from 'src/components/CitySelection';

export default {
  props: {
    callback: {
      type: Function,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    needMobile: {
      type: Boolean,
      default: false,
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
    mobileHidden: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MapPinIcon,
    ArrowDownIcon,
    CitySelection,
  },

  computed: {
    ...mapGetters('profile', { profile: 'fields' }),
    ...mapGetters('common', { redirect: 'redirect' }),

    currentCityName() {
      return this.profile.currentCityName;
    },
  },

  methods: {
    async onChangeCity() {
      await this.$store.dispatch('common/checkRedirect', { path: this.$route.path });
      if (this.redirect) {
        window.location = this.redirect;
      } else {
        window.location.reload();
      }
    },
  },
};
</script>

<template>
  <CitySelection v-bind="$props" :callback="onChangeCity">
    <slot>
      <span class="header__nav-link header__nav-link--city">
        <MapPinIcon class="header__nav-link-icon header__nav-link-icon--map-pin" />
        {{ currentCityName }}
        <ArrowDownIcon class="header__nav-link-icon header__nav-link-icon--arrow" />
      </span>
    </slot>
  </CitySelection>
</template>

<style lang="scss" scoped src="./style.scss"></style>
