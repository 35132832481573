var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOutside",
          rawName: "v-clickOutside",
          value: _vm.closeMenu,
          expression: "closeMenu"
        }
      ],
      staticClass: "c-select-menu"
    },
    [
      _c(
        "div",
        {
          staticClass: "c-select-menu__selected",
          on: { click: _vm.onClickSelected }
        },
        [
          _vm.activeItem
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.activeItem[_vm.accessValue]) +
                    "\n    "
                )
              ]
            : [_vm._v(" ")],
          _vm._v(" "),
          _c("img", {
            staticClass: "c-select-menu__arrow",
            attrs: { src: _vm.arrowImage }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "c-select-menu__list"
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "li",
            {
              key: item[_vm.accessKey],
              staticClass: "c-select-menu__item",
              on: {
                click: function($event) {
                  return _vm.onClickItem(item)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(item[_vm.accessValue]) + "\n    ")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }