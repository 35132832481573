var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.computedMainPart, {
    tag: "component",
    attrs: { isMain: _vm.isMainPage, isCart: _vm.isCartPage }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }