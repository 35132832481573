<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('profile', ['inited', 'fields']),

    messages() {
      return this.inited && Array.isArray(this.fields.messages) ? this.fields.messages : [];
    },
  },
  mounted() {
    window.xcore.bind('message', (event, data) => {
      const messages = [...this.messages, { text: data.message, type: data.type }];
      this.$store.commit('profile/setField', { key: 'messages', value: messages });
    });
  },
  methods: {
    getPrefix(message) {
      if (message.type === 'error') {
        return 'Ошибка:';
      }
      if (message.type === 'warning') {
        return 'Предупреждение:';
      }
      return '';
    },
    close() {
      this.$store.commit('profile/setField', { key: 'messages', value: [] });
    },
  },
};
</script>

<template>
  <div id="status-messages" v-if="messages.length > 0">
    <span class="close" title="Закрыть" @click.prevent="close()">
      <img src="/frontend-new/components/TopMessages/images/spacer.gif" alt="Закрыть" />
    </span>
    <ul>
      <li v-for="(message, index) in messages" :key="index" :class="message.type">
        <em v-if="getPrefix(message)">{{ getPrefix(message) }}</em>
        {{ message.text }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>
