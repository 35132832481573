var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages.length > 0
    ? _c("div", { attrs: { id: "status-messages" } }, [
        _c(
          "span",
          {
            staticClass: "close",
            attrs: { title: "Закрыть" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.close()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: "/frontend-new/components/TopMessages/images/spacer.gif",
                alt: "Закрыть"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.messages, function(message, index) {
            return _c("li", { key: index, class: message.type }, [
              _vm.getPrefix(message)
                ? _c("em", [_vm._v(_vm._s(_vm.getPrefix(message)))])
                : _vm._e(),
              _vm._v("\n      " + _vm._s(message.text) + "\n    ")
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }