var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "header__search",
      attrs: { action: "cart.php", method: "get", "accept-charset": "utf-8" },
      on: { submit: _vm.onFormSubmit }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("button", {
        staticClass: "header__search-submit",
        attrs: { type: "submit" }
      }),
      _vm._v(" "),
      _c("SearchIcon", { staticClass: "header__search-icon" }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.substring,
            expression: "substring"
          }
        ],
        staticClass: "header__search-input",
        attrs: {
          type: "text",
          placeholder: "Поиск в онлайн-магазине оптики",
          size: "30",
          maxlength: "200",
          name: "substring",
          title: "Поиск"
        },
        domProps: { value: _vm.substring },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.substring = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-params", staticStyle: { display: "none" } },
      [
        _c("input", {
          attrs: { type: "hidden", name: "target", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "action", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "mode", value: "search" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "searchInSubcats", value: "Y" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "including", value: "all" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }